import { eventName, pageTags } from "../DataLayerContext";
import { checkArgs, isLogged, resolveFamilyName } from "../../../utils/DataLayer";

export function buildWBCHopOnEvent({
  familyName = "",
  modelName = "",
  equipmentName = "",
  cid = "",
  items = [],
  totalConfigurationValue = 0,
  supermodelName = "",
  currency = "",
}) {
  let wbcHopOnEvent = {};
  const realFamilyName = resolveFamilyName(familyName);
  if (checkArgs([realFamilyName, modelName, cid])) {
    const fixedPrice = totalConfigurationValue > 0 && totalConfigurationValue !== "" ? totalConfigurationValue.toFixed(2) : 0;
    const login = isLogged();
    wbcHopOnEvent = {
      event: eventName.clickHopOn,
      pageTags: pageTags.configurator,
      login,
      eventCategory: "Configuration page",
      eventAction: "Hop on your Motorbike",
      value: fixedPrice !== 0 ? fixedPrice.toString() : 0,
      eventLabel: modelName,
      items,
      transaction_id: cid,
      DL_familyName: realFamilyName,
      DL_supermodelName: supermodelName,
      DL_modelName: modelName,
      DL_equipmentName: equipmentName,
      currency,
    };
  }
  return wbcHopOnEvent;
}

export function buildWBCHopOnEventMTO({
  familyName = "",
  modelName = "",
  equipmentName = "",
  cid = "",
  items = [],
  totalConfigurationValue = 0,
  supermodelName = "",
  currency = "",
}) {
  let wbcHopOnEvent = {};
  const realFamilyName = resolveFamilyName(familyName);
  if (checkArgs([realFamilyName, modelName, cid])) {
    const login = isLogged();
    wbcHopOnEvent = {
      event: eventName.clickHopOn,
      pageTags: pageTags.configurator,
      login,
      eventCategory: "Configuration page",
      eventAction: "Hop on your Motorbike",
      value: totalConfigurationValue,
      eventLabel: modelName,
      items,
      transaction_id: cid,
      DL_familyName: realFamilyName,
      DL_supermodelName: supermodelName,
      DL_modelName: modelName,
      DL_equipmentName: equipmentName,
      currency,
    };
  }
  return wbcHopOnEvent;
}

