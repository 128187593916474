import React, { useEffect, useRef } from "react";
import style from "../Modal.module.scss";
import { Search, Text } from "../../../context/LanguageContext";
import { Modal } from "bootstrap";
import { useModalContent } from "../../../context/ModalContext";
import { useScrambler } from "../../../hooks/useScrambler";
import styleBody from "../../../layouts/content/configurator-step/step-10-apparel/highlighted-modal/RemoveHighlightedModal.module.scss";
import css from "./ServiceModal.module.scss";
import { useAftermarketMTO } from "../../../hooks/useAftermarketMTO";
import { isDesktopView } from "../../../utils/Sizing";
import { cn } from "../../../utils/cn";
import plus from "../../../assets/images/ModalIncompatibility/plus.svg";
import { useMTO } from "../../../hooks/useMTO";
import { useMTOContext } from "../../../context/MTOContext";

const ServiceModalId = "service-modal-id";

export const messageType = {
  changingFail: {
    title: "modal.config_changes.messages.fail.title",
    description: "modal.config_changes.messages.fail.subtitle",
  },
  configIncomplete: {
    title: "modal.config_incomplete.messages.fail.title",
    description: "modal.config_incomplete.messages.fail.subtitle",
  },
  exceededMaxRate: {
    title: "modal.config_changes.messages.fail.title",
    description: "modal.loan.max_rate",
  },
  accessoryInCart: {
    title: "modal.accessory.bundle.title",
    description: "modal.accessory.already.in.cart.subtitle",
  },

  accessoryInCartSingle: {
    title: "modal.accessory.bundle.title",
    description: "modal.accessory.already.in.cart.single.subtitle",
  },

  accessoryInBundle: {
    title: "modal.accessory.bundle.title",
    description: "modal.accessory.already.in.bundle.first.subtitle",
    descriptionSecond: "modal.accessory.already.in.bundle.second.subtitle",
  },

  accessoryInBundleSingle: {
    title: "modal.accessory.bundle.title",
    description: "modal.accessory.already.in.optional.first.subtitle",
    descriptionSecond: "modal.accessory.already.in.bundle.second.subtitle",
  },

  seatInCart: {
    title: "modal.accessory.bundle.title",
    description: "modal.seat.in.cart.subtitle",
  },

  seatAlreadyInCart: {
    title: "modal.accessory.bundle.title",
    description: "modal.seat.already.in.cart.subtitle",
  },

  rimsInCart: {
    title: "modal.accessory.bundle.title",
    description: "modal.rims.already.in.cart.subtitle",
  },
};

export function useServiceModal() {
  const { setServiceModal } = useModalContent();
  const showServiceModal = (message, item = false, bundleItem = false) => {
    setServiceModal({
      showSericeModal: true,
      title: message.title,
      description: message.description,
      descriptionSecond: message.descriptionSecond || "",
      accessory: item,
      bundle: bundleItem,
      bundleName: bundleItem?.name || "",
    });
  };

  const hideServiceModal = () => {
    setServiceModal({ showSericeModal: false });
  };

  return { showServiceModal, hideServiceModal };
}

export function ServiceModal() {
  const modalRef = useRef(null);
  const { serviceModal } = useModalContent();

  useEffect(() => {
    if (!modalRef.current) return;
    const modalChild = new Modal(modalRef?.current);
    if (modalChild) {
      if (serviceModal?.showSericeModal) {
        modalChild.show();
      } else {
        modalChild.hide();
      }
    }
  }, [serviceModal]);

  return (
    <div
      ref={modalRef}
      className={`modal fade ${style.background} ${style.mandatory}`}
      id={ServiceModalId}
      tabIndex="-1"
      aria-labelledby={ServiceModalId}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered justify-content-center modal-fullscreen-lg-down">
        <div className={`modal-content ${style.content}`}>
          <div className={`modal-body p-0 ps-lg-0 ${style["max-height-conflict"]} `}>
            <ServiceModalBody
              tidTitle={serviceModal.title}
              tidDesc={serviceModal.description}
              accessory={serviceModal.accessory}
              bundle={serviceModal.bundle}
              bundleName={serviceModal.bundleName}
              tidDescSec={serviceModal.descriptionSecond}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ServiceModalBody({ tidTitle = "", tidDesc = "", accessory = "", bundle = "", bundleName = "", tidDescSec = "" }) {
  const { hideServiceModal } = useServiceModal();
  const { scramblerStyle } = useScrambler();
  const { updateAccessory } = useAftermarketMTO();
  const { patchConfig } = useMTO();
  const { state: mtoContent } = useMTOContext();

  function ConflictCard({ conflictItem, itemInChart }) {
    return (
      conflictItem && (
        <div
          data-component="cmb-content"
          className={cn("d-flex mx-0", css["cmb-content"], {
            "flex-column": !isDesktopView(),
          })}
        >
          <div data-component="cmb-content-left" className={cn("d-flex flex-column mx-0", css["cmb-content-left"])}>
            <p className={css.desc_left}>
              <Search did="modal_labels" tid="modal.article.adding.to.chart" />
            </p>

            <div
              className={cn("d-flex", css["card-container"], {
                "flex-lg-row align-items-start": isDesktopView(),
                "flex-column h-auto": !isDesktopView(),
              })}
            >
              <div className={`card d-flex flex-row d-lg-inline-block border-0 ${css.card}`}>
                <img
                  src={conflictItem?.image || conflictItem?.images}
                  className={`card-img-top ${css["card-img"]}`}
                  width={isDesktopView() ? "150" : "124"}
                  height={isDesktopView() ? "150" : "124"}
                  alt={conflictItem.name || conflictItem.langDepName}
                />
                <div className={`card-body d-flex flex-column justify-content-between d-lg-block ${css["card-body"]} `}>
                  <p className={`card-text ${css["card-text-title"]}`}>{conflictItem.name || conflictItem.langDepName}</p>
                  <p className={`card-text ${css["card-text-description"]}`}>{conflictItem.domainValueKey || ""}</p>

                  <p className={`card-text text-end ${css["card-price-description"]}`}>
                    {conflictItem?.price?.formattedValue !== "" ? `+ ${conflictItem?.price?.formattedValue}` : ""}
                  </p>
                </div>
              </div>
              <div
                className={cn("align-self-center d-inline-block", {
                  "py-4": !isDesktopView(),
                  [css.margin6]: isDesktopView(),
                })}
              >
                <img src={plus} alt="plus sign" className={css["cmb-content-center"]} />
              </div>
            </div>
          </div>

          <div data-component="cmb-content-right" className={cn("d-flex flex-column mx-0", css["cmb-content-right"])}>
            <p className={css.desc_right}>
              <Search did="modal_labels" tid="modal.accessory.already.in.chart" />
            </p>
            <div
              className={cn("d-flex", css["card-container"], {
                "flex-lg-row align-items-start": isDesktopView(),
                "flex-column h-auto": !isDesktopView(),
              })}
            >
              <div className={`card d-flex flex-row d-lg-inline-block border-0 ${css.card}`}>
                <img
                  src={itemInChart?.image || itemInChart?.images}
                  className={`card-img-top ${css["card-img"]}`}
                  width={isDesktopView() ? "150" : "124"}
                  height={isDesktopView() ? "150" : "124"}
                  alt={itemInChart.name || itemInChart.langDepName}
                />
                <div className={`card-body d-flex flex-column justify-content-between d-lg-block ${css["card-body"]} `}>
                  <p className={`card-text ${css["card-text-title"]}`}>{itemInChart.name || itemInChart.langDepName}</p>
                  <p className={`card-text ${css["card-text-description"]}`}>{itemInChart.domainValueKey || ""}</p>
                  <p className={`card-text text-end ${css["card-price-description"]}`}>
                    {itemInChart?.price?.formattedValue !== "" ? `+ ${itemInChart?.price?.formattedValue}` : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className={` d-flex justify-content-center align-items-center flex-column w-100`}>
      <div className={` d-flex justify-content-center align-items-center flex-column h-50`}>
        <h3 className={`pt-3 ${style.modalObject_title}`}>
          <Search did="modal_labels" tid={tidTitle} />
        </h3>
        {tidDesc === messageType.accessoryInBundle.description || tidDesc === messageType.accessoryInBundleSingle.description ? (
          <p className={`${style.modalObject_subtitle_conflict} px-4`}>
            <Search did="modal_labels" tid={tidDesc} />
            {bundleName}
            <Search did="modal_labels" tid={tidDescSec} />
          </p>
        ) : (
          <p className={`${style.modalObject_subtitle} px-4`}>
            <Search did="modal_labels" tid={tidDesc} />
          </p>
        )}
      </div>

      {tidTitle === messageType.accessoryInCart.title || tidTitle === messageType.seatInCart.title ? (
        <div>
          <div>
            <div data-component="minicard" className={`mx-0 px-0 ${css["check-box-list"]}`}>
              <ConflictCard conflictItem={accessory} itemInChart={bundle} />
            </div>
          </div>

          <br />
          <div data-component="remove-action-buttons" className={`row px-0 px-0 ${styleBody["remove-action-buttons"]}`}>
            <div className={`col mx-0 px-0 pe-lg-2 text-end order-2 order-lg-1 ${styleBody["button-style"]}`}>
              <button
                type="button"
                className={`${styleBody["btn-transparent"]} ${styleBody["cancel-btn-conflict"]}`}
                data-bs-dismiss="modal"
                onClick={async () => {
                  // tasto annulla
                  // on click non devi aggiungere ne accessorio oppure bundle
                  if (
                    tidDesc === messageType.accessoryInCart.description ||
                    tidDesc === messageType.accessoryInCartSingle.description ||
                    tidDesc === messageType.accessoryInBundle.description ||
                    tidDesc === messageType.accessoryInBundleSingle.description ||
                    tidDesc === messageType.seatAlreadyInCart.description ||
                    tidDesc === messageType.seatInCart.description ||
                    tidDesc === messageType.rimsInCart.description
                  ) {
                    let itemToRemove = "";
                    if (
                      tidDesc === messageType.accessoryInBundle.description ||
                      tidDesc === messageType.accessoryInBundleSingle.description ||
                      tidDesc === messageType.seatAlreadyInCart.description ||
                      tidDesc === messageType.seatInCart.description ||
                      tidDesc === messageType.rimsInCart.description
                    ) {
                      itemToRemove = mtoContent.pilotSeat && tidDesc === messageType.seatAlreadyInCart.description ? bundle : accessory;

                      await updateAccessory({
                        accessory: itemToRemove,
                        caller: "Service Modal - removeAccessory",
                      });
                    } else if (
                      tidDesc === messageType.accessoryInCart.description ||
                      tidDesc === messageType.accessoryInCartSingle.description ||
                      tidDesc === messageType.seatInCart.description ||
                      tidDesc === messageType.rimsAlreadyInCart.description
                    ) {
                      if (mtoContent.pilotSeat) {
                        await patchConfig({
                          domainValueKey: accessory.domainValueKey,
                          attributeKey: accessory.attributeKey,
                          groupId: accessory.groupId,
                          caller: "ServiceModal - remove",
                          selected: true,
                        });
                      } else {
                        await patchConfig({
                          domainValueKey: accessory.domainValueKey,
                          attributeKey: accessory.attributeKey,
                          groupId: accessory.groupId,
                          caller: "ServiceModal - remove",
                          selected: true,
                        });
                      }
                    }
                  }

                  hideServiceModal();
                  // adding accessory already is in a bundle in cart
                }}
              >
                <Text tid="popup.choose_color.btn_cancel" />
              </button>
            </div>
            <div className={`col mx-0 px-0 ps-lg-2 text-start order-1 order-lg-2 ${styleBody["button-style"]}`}>
              <button
                type="button"
                className={`${scramblerStyle ? styleBody["btn-yellow"] : styleBody["btn-red"]} ${styleBody["cancel-btn-conflict"]}`}
                data-bs-dismiss="modal"
                onClick={() => {
                  // tasto conferma
                  // aggiungi e non fare nulla
                  hideServiceModal();
                }}
              >
                <Text tid="configurator.change_model.btn_confirm" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-50">
          <button
            type="button"
            className={`p-3 text-uppercase m-5 ${styleBody["btn-red"]} ${styleBody["button-style"]}`}
            data-bs-dismiss="modal"
            onClick={() => {
              hideServiceModal();
            }}
          >
            <Search did="configurator_information" tid="recapbar.messages.next" />
          </button>
        </div>
      )}
    </div>
  );
}

