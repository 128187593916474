import React, { useState, createContext, useContext, useEffect } from "react";
import { InitContext } from "./InitContext";
import { createImageURL } from "../utils/createImageURL";
import accessories from "./accessories.json";
import MountainRoadDayImage from "../assets/images/carousel/Mountain_Road_Day.png";
import MountainRoadNightImage from "../assets/images/carousel/Mountain_Road_Night.png";
import StudioDayImage from "../assets/images/carousel/Studio_Day.png";
import StudioNightImage from "../assets/images/carousel/Studio_Night.png";
import OffroadDayImage from "../assets/images/carousel/Offroad_Day.png";
import OffroadNightImage from "../assets/images/carousel/Offroad_Night.png";
import PiazzaDayImage from "../assets/images/carousel/Piazza_Day.png";
import PiazzaNightImage from "../assets/images/carousel/Piazza_Night.png";
import RacetrackDaytImage from "../assets/images/carousel/Racetrack_Day.png";
import RacetrackNightImage from "../assets/images/carousel/Racetrack_Night.png";
import LuxuryVillaDayImage from "../assets/images/carousel/LuxuryVilla_Day.png";
import LuxuryVillaNightImage from "../assets/images/carousel/LuxuryVilla_Night.png";
import { DEFAULT_ENV_MAP_MTS } from "./CarouselState";
import { hasBikeEnvironmentToRemove } from "../components/modal/conflict-modal-mto/ConflictState";

export const RENDERING_SYSTEM = {
  clx: "CLX",
  mhp: "MHP",
  mhpSlimSdk: "MHP-SLIM-SDK",
};

export const DEFAULT_STATE = {
  composedAssets: null,
  rendering: RENDERING_SYSTEM.clx,
  ambience: RENDERING_SYSTEM.clx,
  selectedImage: 0,
  baseImages: [],
  images: [],
  name: undefined,
  motorbikeCode: undefined,
  motorbikeId: undefined,
  showArrow: false,
  isScrolling: false,
  isLoading: true,
  selectedCamera: "Ext_Side_R",
  environmentsImages: [StudioDayImage, MountainRoadDayImage, MountainRoadDayImage, PiazzaDayImage, OffroadDayImage, RacetrackDaytImage],
  environments: ["env_mountainroad"],
  selectedEnvironment: "env_mountainroad",
  isNightMode: false,
  imageIsLoading: false,
  noshowAccessories: undefined,
  hasErrorOnLoad: false,
};

export const CarouselContext = createContext({
  state: DEFAULT_STATE,
});

function sortViews(views) {
  const commonKeys = ["Ext_Side_R", "Ext_Side_L", "Ext_34_Front", "Ext_34_Rear", "Ext_34_FrontLights", "Ext_Side_Tank"];

  const commonViews = [];
  const differentViews = [];

  for (const view of views) {
    if (commonKeys.includes(view.view)) {
      commonViews.push(view);
    } else {
      differentViews.push(view);
    }
  }

  return commonViews.concat(differentViews);
}

export function CarouselProvider({ defaultState, children }) {
  const { initSessionContent } = useContext(InitContext);
  const [carouselContent, setCarouselContent] = useState({
    ...DEFAULT_STATE,
    ...defaultState,
  });

  const setStuff = key => {
    return value => {
      if (carouselContent[key] === value) return;
      setCarouselContent(prev => ({ ...prev, [key]: value }));
    };
  };

  // Get the motorbike name, code and id from the initSession
  useEffect(() => {
    if (initSessionContent?.configuration?.preconfiguration?.name) setStuff("name")(initSessionContent.configuration.preconfiguration.name);
    if (initSessionContent?.configuration?.preconfiguration?.model_code)
      setStuff("motorbikeCode")(initSessionContent.configuration.preconfiguration.model_code.toLowerCase());
    if (initSessionContent?.configuration?.preconfiguration?.id)
      setStuff("motorbikeId")(initSessionContent.configuration.preconfiguration.id);
  }, [
    initSessionContent?.configuration?.preconfiguration?.model_code,
    initSessionContent?.configuration?.preconfiguration?.name,
    initSessionContent?.configuration?.preconfiguration?.id,
  ]);

  // Load accessories to not show & load env images
  useEffect(() => {
    if (!carouselContent.motorbikeCode) return;

    // TODO
    const environmentImagesList = [];
    if (initSessionContent?.configuration?.preconfiguration?.environments?.length > 0) {
      const listenvCode = initSessionContent?.configuration?.preconfiguration?.environments
        .map(item => item.code.toLowerCase())
        .filter(item => item !== hasBikeEnvironmentToRemove(carouselContent?.motorbikeCode));
      for (let i = 0; i < listenvCode.length; i++) {
        switch (listenvCode[i]) {
          case "env_studio":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(StudioDayImage);
            } else {
              environmentImagesList.push(StudioNightImage);
            }
            break;
          case "env_mountainroad":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(MountainRoadDayImage);
            } else {
              environmentImagesList.push(MountainRoadNightImage);
            }
            break;
          case "env_offroad":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(OffroadDayImage);
            } else {
              environmentImagesList.push(OffroadNightImage);
            }
            break;
          case "env_urban":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(PiazzaDayImage);
            } else {
              environmentImagesList.push(PiazzaNightImage);
            }
            break;
          case "env_racetrack":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(RacetrackDaytImage);
            } else {
              environmentImagesList.push(RacetrackNightImage);
            }
            break;
          case "env_luxuryvilla":
            if (!carouselContent.isNightMode) {
              environmentImagesList.push(LuxuryVillaDayImage);
            } else {
              environmentImagesList.push(LuxuryVillaNightImage);
            }
            break;
          default:
            break;
        }
      }
    }

    setStuff("environmentsImages")(environmentImagesList);

    const selected = accessories[carouselContent.motorbikeCode];
    if (!selected) return;

    const set = new Set(selected);
    setStuff("noshowAccessories")(set);
  }, [carouselContent.motorbikeCode, carouselContent.isNightMode, carouselContent.motorbikeId]);

  // This useEffect manages the default environment based on the selected bike
  useEffect(() => {
    if (carouselContent?.motorbikeCode && DEFAULT_ENV_MAP_MTS.has(carouselContent?.motorbikeCode)) {
      setStuff("selectedEnvironment")(DEFAULT_ENV_MAP_MTS.get(carouselContent?.motorbikeCode));
    }
  }, [carouselContent.motorbikeCode]);

  // Initialize the ambience from the initSession
  useEffect(() => {
    if (initSessionContent?.configuration?.preconfiguration?.mhp === undefined) {
      setStuff("ambience")(RENDERING_SYSTEM.mhpSlimSdk);
      setStuff("rendering")(RENDERING_SYSTEM.mhpSlimSdk);
    } else {
      const ambience = initSessionContent.configuration.preconfiguration.mhp ? RENDERING_SYSTEM.mhp : RENDERING_SYSTEM.clx;
      setStuff("ambience")(ambience);
      setStuff("rendering")(ambience === RENDERING_SYSTEM.mhp ? RENDERING_SYSTEM.mhp : RENDERING_SYSTEM.clx);
      if (ambience === RENDERING_SYSTEM.clx) setStuff("isLoading")(false);
    }
  }, [initSessionContent?.configuration?.preconfiguration?.mhp]);

  useEffect(() => {
    if (!initSessionContent?.configuration?.mhp_composed_assets) {
      setStuff("isLoading")(true);
      return;
    }
    if (carouselContent.ambience !== RENDERING_SYSTEM.mhp) return;

    const images = initSessionContent.configuration.mhp_composed_assets;

    let newArray;
    if (hasBikeEnvironmentToRemove(carouselContent?.motorbikeCode)) {
      newArray = images.filter(item => item?.code !== hasBikeEnvironmentToRemove(carouselContent?.motorbikeCode));
    }
    setStuff("composedAssets")(newArray || images);
  }, [carouselContent.ambience, initSessionContent?.configuration?.mhp_composed_assets]);

  // Set baseImages
  useEffect(() => {
    if (!carouselContent.composedAssets) return;
    if (carouselContent.ambience !== RENDERING_SYSTEM.mhp) return;

    const filtered = carouselContent.composedAssets.map(record => {
      return {
        ...record,
        views: sortViews(
          record.views
            // Remove Turntable view
            .filter(({ view }) => view !== "Turntable" && view !== "Path_Turntablecamera" && view !== "path_turntablecamera")
            // Add query parameters for isNight
            .map(({ view, url }) => {
              return {
                view,
                url: createImageURL(url, {
                  isNightMode: carouselContent.isNightMode,
                  resize: "2800:1080",
                }).toString(),
              };
            })
        ),
      };
    });

    setStuff("baseImages")(filtered);
  }, [carouselContent.ambience, carouselContent.isNightMode, carouselContent.composedAssets]);

  // Set the images based on the selected environment
  useEffect(() => {
    if (carouselContent.baseImages.length === 0) return;
    const images = carouselContent.baseImages.find(({ code }) => code?.toLowerCase() === carouselContent.selectedEnvironment)?.views; // Remove Turntable since it is broken

    setStuff("images")(images || []);
    setStuff("isLoading")(false);
  }, [carouselContent.baseImages, carouselContent.selectedEnvironment]);

  // Initialize environmentImages
  // Why two useEffect? Because we dont want to run all the heavy logic to pick the images
  // Only to get two images
  useEffect(() => {
    if (carouselContent.baseImages.length === 0) return;

    const selectedEnvCamera = carouselContent.baseImages.find(view => view.code.toLowerCase() === carouselContent.selectedEnvironment);

    const temp = selectedEnvCamera?.views[carouselContent.selectedImage]?.view || "Ext_Side_R";
    setStuff("selectedCamera")(temp);
  }, [carouselContent.baseImages, carouselContent.selectedImage]);

  // Initialize the environments
  // NOTE: Now only for the mts-mhp
  useEffect(() => {
    if (!carouselContent.composedAssets) return;

    const environments = carouselContent.composedAssets.map(image => image.code?.toLowerCase() ?? image.environment?.toLowerCase());
    setStuff("environments")(environments);
  }, [carouselContent.composedAssets]);

  const setEnvironment = index => {
    const newEnvironment = carouselContent.environments[index || 0];

    // Logic to load the right image
    // Requirements:
    // Check if the other environment has the same camera
    // If so: pick the index of that camera,
    // If not: pick the first camera
    const envImages = carouselContent.baseImages[index || 0];
    let cameraIndex = envImages.views.findIndex(({ view }) => view === carouselContent.selectedCamera);
    if (cameraIndex === -1) cameraIndex = 0;

    setCarouselContent(prev => ({
      ...prev,
      selectedEnvironment: newEnvironment,
      selectedImage: cameraIndex,
    }));
  };

  // Add loader for the current image
  useEffect(() => {
    const img = new Image();
    img.src = carouselContent.images[carouselContent.selectedImage]?.url;
    const isComplete = img.complete;

    if (!isComplete) {
      setStuff("imageIsLoading")(true);
      setStuff("hasErrorOnLoad")(false);
      img.onload = () => {
        setCarouselContent(prev => ({
          ...prev,
          imageIsLoading: false,
          hasErrorOnLoad: false,
        }));
      };
      img.onerror = () => {
        setCarouselContent(prev => ({
          ...prev,
          imageIsLoading: false,
          hasErrorOnLoad: true,
        }));
      };
    } else {
      setStuff("imageIsLoading")(false);
      setStuff("hasErrorOnLoad")(false);
    }
  }, [carouselContent.images, carouselContent.selectedImage]);

  const getRendering = () => {
    return carouselContent.rendering;
  };

  const provider = {
    state: carouselContent,
    setContent: setCarouselContent,
    setImages: setStuff("images"),
    setSelectedImage: setStuff("selectedImage"),
    setIsNightMode: setStuff("isNightMode"),
    setShowArrow: setStuff("showArrow"),
    setIsScrolling: setStuff("isScrolling"),
    setIsLoading: setStuff("isLoading"),
    updatePosition: setStuff("selectedImage"),
    changeEnvironment: setEnvironment,
    getRendering,
  };

  return <CarouselContext.Provider value={provider}>{children}</CarouselContext.Provider>;
}

export function useCarousel() {
  return useContext(CarouselContext);
}

