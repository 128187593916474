import { useEffect, useState } from "react";
import { useEnabledCountriesContent } from "../../../common/context/EnabledCountriesContext";
import { useLanguageContent } from "../../../common/context/LanguageContext";
import { createConfiguratorIndexedDB } from "../../../common/utils/IndexedDB";
import { fetchLocalization } from "../../../common/services/DucatiService";
import { getCountryFromUrl, getLanguage, getLanguageFromUrl, retrievei18n } from "../../../common/utils/Localize";
import { useAuth } from "react-oauth2-pkce";
import { dealerDataFromJWT } from "../../../common/utils/Auth";
import {
  retrievePreconfigurationActiveEnvironment,
  retrievePreconfigurationDeltaPrices,
} from "../../../common/assets/resources/DucatiResources";
import { NOT_FOUND_CODE, useError } from "../../../common/context/ErrorContext";
import { mergeFamilyList, useFamilyMTOComplete } from "../../../common/context/FamilyMTOContext";
import { useDucatiServiceMTO } from "../../../common/services/useDucatiServiceMTO";
import { useMTO } from "../../../common/hooks/useMTO";

export function useRouteDucati() {
  const country = getCountryFromUrl();
  const language = getLanguage();
  const { authService } = useAuth();
  const { setLanguage } = useLanguageContent();
  const { setEnabledCountriesContent } = useEnabledCountriesContent();
  const [isLoading, setIsLoading] = useState(true);
  const { onError, echoError } = useError();
  const { fetchHierarchyMTO } = useDucatiServiceMTO();
  const { getFamilyListByCountry } = useMTO();
  const { setFamilyMTOComplete, setPreconfigDeltaPrices, setPreconfigActiveMtoEvn } = useFamilyMTOComplete();

  useEffect(() => {
    createConfiguratorIndexedDB();
    Promise.all([
      fetchLocalization(country.toLowerCase(), language.toLowerCase(), dealerDataFromJWT(authService)).then(
        data => {
          if (data) {
            const i18n = data.url_i18n;
            i18n.enabledCountries = data.enabled_countries;
            return i18n;
          }
        },
        error => {
          error.message = NOT_FOUND_CODE;
          error.caller = "useRouteDucati - fetchLocalization";
          throw error;
        }
      ),
      fetchHierarchyMTO().then(
        response => {
          return response;
        },
        error => {
          echoError({
            error,
            caller: "useRouteDucati - fetchHierarchyMTO",
          });
          return [];
        }
      ),
      // TODO: Detla prices, to be removed when BE is updated
      retrievePreconfigurationDeltaPrices(process.env.NODE_ENV)
        .then(response => {
          return response;
        })
        .catch(error => {
          echoError({
            error,
            caller: "Variant - getDeltaPrices - retrievePreconfigurationDeltaPrices",
          });
        }),
      retrievePreconfigurationActiveEnvironment(process.env.NODE_ENV)
        .then(response => {
          return response;
        })
        .catch(error => {
          echoError({
            error,
            caller: "Variant - retrievePreconfigurationActiveEnvironment",
          });
        }),
    ])
      .then(async values => {
        const translations = values[0];
        const responseHierarchy = values[1];
        // TODO: Detla prices, to be removed when BE is updated
        const preconfigDeltaPrices = values[2];
        const preconfigActiveMtoEvn = values[3];

        const additionalInfo = await retrievei18n(translations.language);

        const tempEnabledCountries = translations.enabledCountries;
        delete translations.enabledCountries;
        const i18n = { ...additionalInfo, ...translations };
        setLanguage(i18n);
        setEnabledCountriesContent(tempEnabledCountries);

        const familyListMTO = getFamilyListByCountry(responseHierarchy, country.toLowerCase());
        const mergedFamilyList = mergeFamilyList(familyListMTO, []);
        // TODO: Detla prices, to be removed when BE is updated
        const urlLang = getLanguageFromUrl();
        setPreconfigDeltaPrices(preconfigDeltaPrices, country, urlLang.toLocaleLowerCase());
        setFamilyMTOComplete(mergedFamilyList);
        setPreconfigActiveMtoEvn(preconfigActiveMtoEvn);
        setIsLoading(false);
      })
      .catch(error => {
        onError({
          httpStatusCode: error?.message || NOT_FOUND_CODE,
          consoleError: error,
          caller: error.caller,
        });
        setIsLoading(false);
      });
  }, []);

  return { isLoading };
}
