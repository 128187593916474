export const SEAT_MAP = new Map([
  [
    "MSV4RS",
    {
      riderSeatMap: new Map([
        ["CV000029", ["CV000030"]],
        ["CV000410", ["CV000389", "CV000750", "CV000152"]],
        ["CV000412", ["CV000389", "CV000750", "CV000152"]],
        ["CV002184", ["CV000389", "CV000750", "CV000152"]],
        ["CV000408", ["CV000389", "CV000750", "CV000152"]],
      ]),
      passengerSeatMap: new Map([
        ["CV000030", ["CV000029"]],
        ["CV000389", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000750", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000152", ["CV000410", "CV000412", "CV002184", "CV000408"]],
      ]),
    },
  ],
  [
    "MSV4",
    {
      riderSeatMap: new Map([
        ["CV000406", ["CV000387"]],
        ["CV000410", ["CV000389", "CV000750", "CV000152"]],
        ["CV002184", ["CV000389", "CV000750", "CV000152"]],
        ["CV000408", ["CV000389", "CV000750", "CV000152"]],
        ["CV000412", ["CV000389", "CV000750", "CV000152"]],
      ]),
      passengerSeatMap: new Map([
        ["CV000387", ["CV000406"]],
        ["CV000389", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000750", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000152", ["CV000410", "CV000412", "CV002184", "CV000408"]],
      ]),
    },
  ],
  [
    "MSV4PP",
    {
      riderSeatMap: new Map([
        ["CV000028", ["CV000074"]],
        ["CV000410", ["CV000389", "CV000750", "CV000152"]],
        ["CV002184", ["CV000389", "CV000750", "CV000152"]],
        ["CV000408", ["CV000389", "CV000750", "CV000152"]],
        ["CV000412", ["CV000389", "CV000750", "CV000152"]],
      ]),
      passengerSeatMap: new Map([
        ["CV000074", ["CV000028"]],
        ["CV000389", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000750", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000152", ["CV000410", "CV000412", "CV002184", "CV000408"]],
      ]),
    },
  ],
  [
    "MSV4S",
    {
      riderSeatMap: new Map([
        ["CV000406", ["CV000387"]],
        ["CV000410", ["CV000389", "CV000750", "CV000152"]],
        ["CV002184", ["CV000389", "CV000750", "CV000152"]],
        ["CV000408", ["CV000389", "CV000750", "CV000152"]],
        ["CV000412", ["CV000389", "CV000750", "CV000152"]],
      ]),
      passengerSeatMap: new Map([
        ["CV000387", ["CV000406"]],
        ["CV000389", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000750", ["CV000410", "CV000412", "CV002184", "CV000408"]],
        ["CV000152", ["CV000410", "CV000412", "CV002184", "CV000408"]],
      ]),
    },
  ],
]);

export const ACCESSORY_BUNDLE = new Map([
  ["CT000173", "CT000383"],
  ["CT000173", "CT000369"],
  ["CT000079", "CT000377"],
  ["ZCOL_RUOTE", "CT000133"],
]);

export const BUNDLE_ACCESSORY = new Map([
  ["CT000383", "CT000173"],
  ["CT000369", "CT000173"],
  ["CT000377", "CT000079"],
  ["CT000133", "ZCOL_RUOTE"],
]);

export const isAccessoryInBundle = (key = "") => {
  return ACCESSORY_BUNDLE.get(key);
};

export const hasBundleAccessory = (key = "") => {
  return BUNDLE_ACCESSORY.get(key);
};

export const BIKE_ENVIRONMENT = new Map([["cr450mx", "env_studio"]]);

export const hasBikeEnvironmentToRemove = (key = "") => {
  return BIKE_ENVIRONMENT.get(key);
};

export const getCompatibleSeats = (version = "", key = "") => {
  let result = [];
  let isRider = false;
  const toUpperVersion = version.toUpperCase();
  const seatMap = SEAT_MAP.get(toUpperVersion);
  if (seatMap?.riderSeatMap?.size > 0) {
    const riderList = seatMap.riderSeatMap.get(key);
    if (riderList?.length > 0) {
      result = riderList;
      isRider = true;
    }
  }
  if (seatMap?.passengerSeatMap?.size > 0 && !isRider) {
    const passengerList = seatMap.passengerSeatMap.get(key);
    if (passengerList?.length > 0) {
      result = passengerList;
    }
  }
  return result;
};

