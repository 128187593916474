import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import { useLanguageContent } from "./LanguageContext";
import { LIGHTING_STATE } from "../layouts/left-side/hooks/useEnvironmentsMTO";
import accessories from "./mto_accessories_visible.json";

export const transparentType = {
  screenshot: "screenshot",
  view360: "view360",
};

const DEFAULT_CART_STATE = {
  bikePriceFormatted: "",
  optionPriceFormatted: "",
  accessoryPriceFormatted: "",
  apparelPriceFormatted: "",
  subTotalPriceFormatted: "",
  bikeBasePriceFormatted: "",
  /**
   *  groupedItemMap is a Map of Map where each pair has this format
   *  pair: <attributeName,<itemKey,itemValue>>
   *  attributeName: is the name of attribute retrieved from fetch
   *  itemKey: is the join bwtween attributeKey and domainValueKey
   *  itemValue: is an object with these attributes
   *    {
   *      groupId,
   *      attributeKey,
   *      domainValueKey,
   *      name,
   *      attributeName,
   *      price,
   *      quantity
   *    }
   */
  groupedItemMap: new Map(),
};

const DEFAULT_QUOTATION_STATE = {
  accessorySection: {
    discountPriceFormatted: "",
    discountType: "",
    discountValue: 0,
    finalPriceValueFormatted: "",
    finalPriceValue: 0,
  },
  apparelSection: {
    discountPriceFormatted: "",
    discountType: "",
    discountValue: 0,
    finalPriceValueFormatted: "",
    finalPriceValue: 0,
  },
  bikeSection: {
    discountPriceFormatted: "",
    discountType: "",
    discountValue: 0,
    finalPriceValueFormatted: "",
    finalPriceValue: 0,
  },
  overallSection: {
    discountPriceFormatted: "",
    discountType: "",
    discountValue: 0,
    finalPriceValueFormatted: "",
    finalPriceValue: 0,
    totalPrice: 0,
    totalPriceFormatted: "",
    totalDiscount: 0,
    totalDiscountFormatted: "",
  },
  additionalEntrySection: {
    additionalEntryPrice: 0,
    additionalEntryPriceFormatted: "",
    additionalEntryItemTypeList: [],
  },
  opportunity: {
    id: "",
    testRideDone: false,
    testRidePlanned: false,
    expirationDate: "",
  },
  tradeInBike: {
    notes: "",
    matriculationDate: "",
    value: 0,
    unitOfMeasure: "KM",
    mileage: 0,
    licensePlate: "",
    modelYear: 0,
    productId: "",
    vin: "",
    brand: "",
  },
  finance: {
    amount: 0,
    deposit: 0,
    period: 0,
    company: "",
    solution: "",
    effectiveAPR: 0,
    apr: 0,
    monthlyPayment: 0,
    guaranteedFutureValue: 0,
    interest: 0,
    preliminaryCost: 0,
    installmentCollectionCost: 0,
    substitutiveTax: 0,
    annualCommunicationFee: 0,
    totalAmountRequested: 0,
    notes: "",
  },
};

export const DEFAULT_STATE = {
  isMTOMHP: false,
  isLoading: true,
  CID: undefined,
  VID: undefined,
  name: "",
  familyName: "",
  superModelCode: "",
  familyCode: "",
  modelName: "",
  modelCode: "",
  modelId: "",
  productCode: "",
  configId: undefined,
  fetchInit: undefined,
  headerMTO: [],
  selectedImage: 0,
  selectedEnv: "env_studio",
  imageIsLoading: false,
  bundleModalBody: undefined,
  cart: DEFAULT_CART_STATE,
  notes: "",
  isViewMode: false,
  complete: true,
  landscapeMode: false,
  productLine: "",
  lightingProfile: LIGHTING_STATE.DAY,
  quotes: DEFAULT_QUOTATION_STATE,
  currencyIso: "EUR",
  visibleAccessories: new Set(),
  urlB2B: "",
  modelYear: "",
  transparentBackground: [],
};

export const MTOContext = createContext({
  state: DEFAULT_STATE,
});

export function MTOProvider({ defaultState, children }) {
  const [mtoContent, setMtoContent] = useState({
    ...DEFAULT_STATE,
    ...defaultState,
  });
  const { language } = useLanguageContent();
  const initializedContext = useRef(false);
  /**
   * If value is true in MTO the scenario is MAKE, otherwise it is TAKE.
   */
  const [enableRedirectOnSave, setEnableRedirectOnSave] = useState(false);

  const setStuff = key => {
    return value => {
      if (mtoContent[key] === value) return;
      setMtoContent(prev => ({ ...prev, [key]: value }));
    };
  };

  function setPatchValue(domainValue, attributeKey, groupId) {
    mtoContent.fetchInit.forEach(element => {
      element.forEach(cha => {
        cha.valueList.forEach(value => {
          if (value.attributeKey === attributeKey) {
            if (value.domainValueKey === domainValue) {
              const itemKey = buildItemKey(attributeKey, domainValue);
              value.selected = !value.selected;
              if (value.selected) {
                addItem(itemKey, {
                  chaKey: value.chaKey,
                  chaName: value.chaName,
                  groupId,
                  attributeKey,
                  domainValueKey: domainValue,
                  name: value.langDepName,
                  attributeName: value.attributeName,
                  price: value.price,
                  image: value.image,
                  description: value.description,
                  selected: value.selected,
                });
              } else {
                removeItem(value.attributeName, itemKey);
              }
            } else {
              value.selected = false;
              removeItem(value.attributeName, buildItemKey(attributeKey, value.domainValueKey));
            }
          }
        });
      });
    });
    const newContext = [...mtoContent.fetchInit];
    setStuff("fetchInit")(newContext);
  }

  function setBundleModalBody({
    card,
    isOptional = false,
    isBundle = false,
    isAccessory = false,
    add2Cart,
    chaKey,
    iterateElement = false,
    iterateList,
  }) {
    const newBundleModalBody = {
      card,
      isBundle,
      isOptional,
      isAccessory,
      add2Cart,
      chaKey,
      iterateElement,
      iterateList,
    };
    setStuff("bundleModalBody")(newBundleModalBody);
  }

  function setAdd2Cart(value) {
    const newBundleModalBody = {
      ...mtoContent.bundleModalBody,
      add2Cart: value,
    };
    setStuff("bundleModalBody")(newBundleModalBody);
  }

  useEffect(() => {
    if (mtoContent?.fetchInit && mtoContent?.modelId !== "") {
      setStuff("isLoading")(false);
      const selected = accessories[mtoContent.modelId];
      if (selected?.length > 0) {
        const set = new Set(selected);
        setStuff("visibleAccessories")(set);
      }
      if (!mtoContent.isMTOMHP) {
        setStuff("isMTOMHP")(true);
      }
    } else {
      setStuff("isLoading")(true);
    }
  }, [mtoContent?.fetchInit, mtoContent?.modelId]);

  useEffect(() => {
    if (mtoContent?.fetchInit && mtoContent?.bundleModalBody) {
      // for (let i = 0; i < mtoContent.fetchInit.length; i++) {
      //   for (let x = 0; x < mtoContent.fetchInit[i].length; x++) {
      //     mtoContent.fetchInit[i][x].forEach((element) => {
      //       if (
      //         element.attributeKey ===
      //         mtoContent.bundleModalBody.attributeKey &&
      //         element.domainValueKey ===
      //         mtoContent.bundleModalBody.domainValueKey
      //       ) {
      //         mtoContent.bundleModalBody.selected = element.selected;
      //       }
      //     });
      //   }
      // }
      const newModalBody = { ...mtoContent.bundleModalBody };
      setStuff("bundleModalBody")(newModalBody);
    }
  }, [mtoContent?.fetchInit]);

  const cleanContext = () => {
    const defaultContent = { ...DEFAULT_STATE };
    const defaultCart = { ...DEFAULT_CART_STATE };
    defaultCart.groupedItemMap = new Map();
    defaultContent.cart = { ...defaultCart };
    return setMtoContent(defaultContent);
  };

  const setEnvironment = id => {
    setStuff("selectedEnv")(id);
  };

  /**
   * This method updates the formatted total prices of cart object.
   * @param {string} bikePriceFormatted
   * @param {string} optionPriceFormatted
   * @param {string} accessoryPriceFormatted
   * @param {string} apparelPriceFormatted
   * @param {string} subTotalPriceFormatted
   */
  const updateTotalPrice = ({
    bikePriceFormatted = "",
    bikeBasePriceFormatted = "",
    optionPriceFormatted = "",
    accessoryPriceFormatted = "",
    apparelPriceFormatted = "",
    subTotalPriceFormatted = "",
  }) => {
    const newCart = { ...mtoContent.cart };
    newCart.bikePriceFormatted = bikePriceFormatted;
    if (bikeBasePriceFormatted.length > 0) {
      newCart.bikeBasePriceFormatted = bikeBasePriceFormatted;
    }
    newCart.optionPriceFormatted = optionPriceFormatted;
    newCart.accessoryPriceFormatted = accessoryPriceFormatted;
    newCart.apparelPriceFormatted = apparelPriceFormatted;
    newCart.subTotalPriceFormatted = subTotalPriceFormatted;
    setStuff("cart")(newCart);
  };

  /**
   * This method adds a new item in cart.itemMap.
   * @param {*} itemKey is the merge of attributeKey and domainValueKey
   * @param {*} itemValue is an object and has these attributes:
   * {
   *  groupId,
   *  attributeKey,
   *  domainValueKey,
   *  name,
   *  attributeName,
   *  price,
   *  image,
   *  description
   * }
   */
  const addItem = (itemKey, itemValue) => {
    const newCart = { ...mtoContent.cart };
    // check if groupedItemMap has elements of specific tab
    if (newCart.groupedItemMap.has(itemValue.attributeName)) {
      const itemMap = newCart.groupedItemMap.get(itemValue.attributeName);
      itemMap.set(itemKey, itemValue);
      newCart.groupedItemMap.set(itemValue.attributeName, itemMap);
    } else {
      const itemMap = new Map();
      itemMap.set(itemKey, itemValue);
      newCart.groupedItemMap.set(itemValue.attributeName, itemMap);
    }
    setStuff("cart")(newCart);
  };

  /**
   * This method removes an item frm cart.itemMap.
   * @param {*} attributeName is the name of the tab
   * @param {*} itemKey is the key of specific item included into attributeName tab
   */
  const removeItem = (attributeName, itemKey) => {
    const newCart = { ...mtoContent.cart };
    // check if groupedItemMap has elements of specific tab
    if (newCart.groupedItemMap.has(attributeName)) {
      const itemMap = newCart.groupedItemMap.get(attributeName);
      // remove item from map
      if (itemMap.has(itemKey)) {
        itemMap.delete(itemKey);
      }
      // remove tab from map
      if (itemMap.size <= 0) {
        newCart.groupedItemMap.delete(attributeName);
      } else {
        newCart.groupedItemMap.set(attributeName, itemMap);
      }
    }
    setStuff("cart")(newCart);
  };

  const buildItemKey = (attributeKey, domainValueKey) => {
    const itemKey =
      domainValueKey?.toLowerCase().includes("true") || domainValueKey?.toLowerCase().includes("false") || domainValueKey === undefined
        ? `${attributeKey}-${attributeKey}`
        : `${attributeKey}-${domainValueKey}`;
    return itemKey;
  };

  const synchronizeCart = (selected, itemValue) => {
    if (selected && !itemValue.groupId.includes("BIT")) {
      const itemKey = buildItemKey(itemValue.attributeKey, itemValue.domainValueKey);
      addItem(itemKey, itemValue);
    }
  };

  const syncCartByList = (attributeName, list = []) => {
    const newCart = { ...mtoContent.cart };
    let itemMap;
    if (newCart.groupedItemMap.has(attributeName)) {
      itemMap = newCart.groupedItemMap.get(attributeName);
      itemMap.clear();
    } else {
      itemMap = new Map();
    }
    if (list.length > 0) {
      list.forEach(itemValue => {
        const itemKey = buildItemKey(itemValue.attributeKey, itemValue.domainValueKey);
        itemMap.set(itemKey, itemValue);
      });
      newCart.groupedItemMap.set(attributeName, itemMap);
    } else {
      newCart.groupedItemMap.delete(attributeName);
    }

    setStuff("cart")(newCart);
  };

  const standardPrice = ({ unformattedPrice, formattedPrice, sign = "" }) => {
    return unformattedPrice > 0
      ? `${sign?.length > 0 ? sign : "+"} ${formattedPrice}`
      : language?.recap_page_informations["card.message.standard_price"] || "";
  };

  function isThereInCart(attributeName, attributeKey, domainValueKey) {
    if (mtoContent?.cart?.groupedItemMap?.size > 0) {
      const cartMap = mtoContent.cart.groupedItemMap;
      const valueMap = cartMap.get(attributeName);
      if (valueMap?.size > 0) {
        const itemKey = `${attributeKey}-${domainValueKey}`;
        return valueMap.has(itemKey);
      }
    }
    return false;
  }

  const updateQuotes = backendResponse => {
    if (backendResponse?.additionalEntrySection && backendResponse?.opportunity) {
      const { accessorySection, apparelSection, bikeSection, overallSection, additionalEntrySection, opportunity } = backendResponse;

      const newQuotes = { ...mtoContent.quotes };
      newQuotes.accessorySection = {
        discountPriceFormatted: accessorySection?.discountPriceFormatted || "",
        discountType: accessorySection?.discountType || "",
        discountValue: accessorySection?.discountValue || 0,
        finalPriceValueFormatted: accessorySection?.finalPriceValueFormatted || "",
        finalPriceValue: accessorySection?.finalPriceValue || 0,
      };
      newQuotes.apparelSection = {
        discountPriceFormatted: apparelSection?.discountPriceFormatted || "",
        discountType: apparelSection?.discountType || "",
        discountValue: apparelSection?.discountValue || 0,
        finalPriceValueFormatted: apparelSection?.finalPriceValueFormatted || "",
        finalPriceValue: apparelSection?.finalPriceValue || 0,
      };
      newQuotes.bikeSection = {
        discountPriceFormatted: bikeSection?.discountPriceFormatted || "",
        discountType: bikeSection?.discountType || "",
        discountValue: bikeSection?.discountValue || 0,
        finalPriceValueFormatted: bikeSection?.finalPriceValueFormatted || "",
        finalPriceValue: bikeSection?.finalPriceValue || 0,
      };
      newQuotes.overallSection = {
        discountPriceFormatted: overallSection?.discountPriceFormatted || "",
        discountType: overallSection?.discountType || "",
        discountValue: overallSection?.discountValue || 0,
        finalPriceValueFormatted: overallSection?.finalPriceValueFormatted || "",
        finalPriceValue: overallSection?.finalPriceValue || 0,
        totalPrice: overallSection?.totalPrice || 0,
        totalPriceFormatted: overallSection?.totalPriceFormatted || "",
        totalDiscount: overallSection?.totalDiscount || 0,
        totalDiscountFormatted: overallSection?.totalDiscountFormatted || "",
      };
      newQuotes.additionalEntrySection = { ...additionalEntrySection };
      newQuotes.opportunity = {
        ...opportunity,
        expirationDate: backendResponse?.quotation?.expirationDate || "",
      };
      if (backendResponse?.quotation?.tradeInBike) {
        newQuotes.tradeInBike = { ...backendResponse.quotation.tradeInBike };
      } else {
        newQuotes.tradeInBike = {
          notes: "",
          matriculationDate: "",
          value: 0,
          unitOfMeasure: "KM",
          mileage: 0,
          licensePlate: "",
          modelYear: 0,
          productId: "",
          vin: "",
        };
      }
      if (backendResponse?.quotation?.finance) {
        newQuotes.finance = { ...backendResponse.quotation.finance };
      } else {
        newQuotes.finance = {
          period: 0,
          company: "",
          solution: "",
          effectiveAPR: 0,
          apr: 0,
          deposit: 0,
          monthlyPayment: 0,
          guaranteedFutureValue: 0,
          amount: 0,
          interest: 0,
          preliminaryCost: 0,
          installmentCollectionCost: 0,
          substitutiveTax: 0,
          annualCommunicationFee: 0,
          totalAmountRequested: 0,
          notes: "",
        };
      }
      setStuff("quotes")(newQuotes);
    }
  };

  const getChaItem = (cha = "", domainValueKey = "") => {
    if (cha.length <= 0 && domainValueKey.length <= 0) return undefined;
    let contextItem;
    mtoContent.fetchInit.forEach(element => {
      const chaItem = element.find(item => item.chaKey === cha);
      if (chaItem?.valueList?.length > 0) {
        contextItem = chaItem.valueList.find(item => item?.domainValueKey === domainValueKey);
      }
    });
    return contextItem;
  };

  const setTransparentBackground = value => {
    let list = [...mtoContent.transparentBackground];
    const found = list.findIndex(item => item === value);
    if (found < 0) {
      list.push(value);
    } else {
      list = mtoContent.transparentBackground.filter(item => item !== value);
    }
    setStuff("transparentBackground")(list);
  };

  const provider = {
    isMTOMHP: mtoContent.isMTOMHP,
    mtoCID: mtoContent.CID,
    initializedContext,
    enableRedirectOnSave,
    state: mtoContent,
    setContent: setMtoContent,
    setIsLoading: setStuff("isLoading"),
    setIsMTO: setStuff("isMTOMHP"),
    setFetchInit: setStuff("fetchInit"),
    cleanContext,
    setHeaderMTO: setStuff("headerMTO"),
    setCID: setStuff("CID"),
    setVID: setStuff("VID"),
    updatePosition: setStuff("selectedImage"),
    setConfigId: setStuff("configId"),
    changeEnvironment: setEnvironment,
    setBikeName: setStuff("name"),
    setPatchValue,
    setBundleModalBody,
    updateTotalPrice,
    addItem,
    removeItem,
    synchronizeCart,
    setAdd2Cart,
    setFamilyName: setStuff("familyName"),
    setFamilyCode: setStuff("familyCode"),
    setSuperModelCode: setStuff("superModelCode"),
    setModelName: setStuff("modelName"),
    setModelCode: setStuff("modelCode"),
    setModelId: setStuff("modelId"),
    setNotes: setStuff("notes"),
    setIsViewMode: setStuff("isViewMode"),
    setCurrencyIso: setStuff("currencyIso"),
    standardPrice,
    setComplete: setStuff("complete"),
    setLandscapeMode: setStuff("landscapeMode"),
    setProductLine: setStuff("productLine"),
    setProductCode: setStuff("productCode"),
    setLightingProfile: setStuff("lightingProfile"),
    isThereInCart,
    updateQuotes,
    setEnableRedirectOnSave,
    setUrlB2B: setStuff("urlB2B"),
    setModelYear: setStuff("modelYear"),
    syncCartByList,
    getChaItem,
    setTransparentBackground,
    setOldPilotSeat: setStuff("oldPilotSeat"),
    setPilotSeat: setStuff("pilotSeat"),
  };

  return <MTOContext.Provider value={provider}>{children}</MTOContext.Provider>;
}

export function useMTOContext() {
  return useContext(MTOContext);
}

export const startedFromType = {
  ORDER: "order",
  STOCK: "stock",
};

