/* eslint-disable guard-for-in */
import italy from "../assets/images/flags/Italy.svg";
import belgium from "../assets/images/flags/Belgium.svg";
import argentina from "../assets/images/flags/Argentina.svg";
import australia from "../assets/images/flags/Australia.svg";
import brazil from "../assets/images/flags/Brazil.svg";
import canada from "../assets/images/flags/Canada.svg";
import colombia from "../assets/images/flags/Colombia.svg";
import germany from "../assets/images/flags/Germany.svg";
import spain from "../assets/images/flags/Spain.svg";
import france from "../assets/images/flags/France.svg";
import india from "../assets/images/flags/India.svg";
import japan from "../assets/images/flags/Japan.svg";
import luxembourg from "../assets/images/flags/Luxembourg.svg";
import malaysia from "../assets/images/flags/Malaysia.svg";
import mexico from "../assets/images/flags/Mexico.svg";
import netherlands from "../assets/images/flags/Netherlands.svg";
import norway from "../assets/images/flags/Norway.svg";
import philippines from "../assets/images/flags/Philippines.svg";
import switzerland from "../assets/images/flags/Schweiz.svg";
import singapore from "../assets/images/flags/Singapore.svg";
import sweden from "../assets/images/flags/Sweden.svg";
import thailand from "../assets/images/flags/Thailand.svg";
import uk from "../assets/images/flags/UnitedKingdom.svg";
import emirates from "../assets/images/flags/UnitedArabEmirates.svg";
import usa from "../assets/images/flags/USA.svg";
import international from "../assets/images/internationalIcon.svg";

import { WIDTH_LARGE_MIN } from "./Sizing";
import { useEffect } from "react";
import {
  AED_SYMBOL,
  CAD_SYMBOL,
  CANADIAN_CURRENCY,
  CHF_SYMBOL,
  DOLLAR_SYMBOL_UNICODE,
  EUR_SYMBOL_UNICODE,
  INDIAN_CURRENCY,
  JAPANESE_CURRENCY,
  KRONE_SYMBOL,
  MEXICAN_CURRENCY,
  NORWEGIAN_CURRENCY,
  PHILIPPINE_CURRENCY,
  PHP_SYMBOL,
  SGD_SYMBOL,
  SINGAPORE_CURRENCY,
  SWISS_CURRENCY,
  UNITED_ARAB_EMIRATES_CURRENCY,
} from "./Currency";

export const scramblerFamilyLabel = "scrambler";
export const offRoadFamilyLabel = "offroad";

export function arrayToMap(array, key) {
  if (array) {
    const map = {};

    for (let i = 0; i < array.length; i++) {
      if (key) map[array[i][key]] = array[i];
      else map[i] = array[i];
    }
    return map;
  }
  return null;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeDuplicateFromList(list, param) {
  return list.filter((value, index, self) => index === self.findIndex(t => t[param] === value[param]));
}

export function ordinamento(list, key, order) {
  function ternary(p1, p2) {
    if (p1[key] < p2[key]) return -1;
    else if (p1[key] > p2[key]) return 1;
    return 0;
  }

  list.sort((p1, p2) => ternary(p1, p2));
}

export function ordinamentoPreconfigByPrice(list, order) {
  function ternary(p1, p2) {
    if (p1.model_data.price.unformatted_amount < p2.model_data.price.unformatted_amount) return -1;
    else if (p1.model_data.price.unformatted_amount > p2.model_data.price.unformatted_amount) return 1;
    return 0;
  }

  list.sort((p1, p2) => ternary(p1, p2));
}

export function getLastNamePath(path) {
  return path.substring(path.lastIndexOf("/") + 1);
}

export function isEmptyObject(object) {
  return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
}

// retreive the first bike id returned by the family api based on bike family and model
// parameters are bikeData and object containing the bike family and model and the family context content
export function RetrieveBikeBaseConfigurationId(bikeData, familyContext) {
  const chosenFamily = familyContext?.family?.filter(familydata => {
    return familydata.id === bikeData.family;
  });
  const chosenBikeId = chosenFamily?.[0]?.models
    ?.filter(bike => {
      return bike.id === bikeData.model;
    })?.[0]
    ?.preconfigurations?.filter(preconfig => {
      return preconfig.restricted === false;
    });
  if (chosenBikeId?.[0]?.id) return chosenBikeId[0].id;
}

export function getInfoBikeFromUrl(pathname) {
  const pathnameSplitted = pathname.split("/");
  const infoBike = {
    family: "",
    model: "",
    bikeId: "",
  };
  if (pathnameSplitted[4]) infoBike.family = pathnameSplitted[4];
  if (pathnameSplitted[5]) infoBike.model = pathnameSplitted[5];
  if (pathnameSplitted[6]) infoBike.bikeId = pathnameSplitted[6];
  return infoBike;
}

export const getCountryName = (country, language) => {
  const countryLowerCase = country.toLowerCase();
  switch (countryLowerCase) {
    case "ar":
      return "argentina";
    case "au":
      return "australia";
    case "br":
      return "brasil";
    case "be":
      switch (language) {
        case "fr":
          return "belgique";
        default:
          return "belgië";
      }

    case "ca":
      return "canada";
    case "co":
      return "colombia";
    case "de":
      return "deutschland";
    case "ae":
      return "united Arab Emirates";
    case "es":
      return "españa";
    case "fr":
      return "france";
    case "in":
      return "india";
    case "it":
      return "italia";
    case "jp":
      return "日本";
    case "lu":
      return "luxembourg";
    case "my":
      return "malaysia";
    case "mx":
      return "méxico";
    case "nl":
      return "nederland";
    case "no":
      return "norge";
    case "ph":
      return "pilipinas";
    case "ch":
      switch (language) {
        case "fr":
          return "suisse";
        case "it":
          return "svizzera";
        default:
          return "schweiz";
      }
    case "sg":
      return "singapore";
    case "se":
      return "sverige";
    case "th":
      return "thailand";
    case "gb":
      return "united kingdom";
    case "us":
      return "united States";
    default:
      return "international";
  }
};

export const getCountryMultipleLanguages = country => {
  switch (country) {
    case "ar":
    case "co":
    case "es":
    case "mx":
      return "es";
    case "br":
      return "pt";
    case "be":
      return ["nl", "fr"];
    case "ca":
      return ["en", "fr"];
    case "de":
      return "de";
    case "ae":
      return "ar";
    case "fr":
      return "fr";
    case "it":
      return "it";
    case "jp":
      return "ja";
    case "lu":
      return ["de", "fr"];
    case "my":
      return "ms";
    case "nl":
      return "nl";
    case "no":
      return "nb";
    case "ch":
      return ["de", "fr", "it"];
    case "se":
      return "sv";
    default:
      return "en";
  }
};

export const getCountryCode = country => {
  switch (country) {
    case "argentina":
      return "ar";
    case "australia":
      return "au";
    case "brasil":
      return "br";
    case "belgië":
      return "be";
    case "canada":
      return "ca";
    case "colombia":
      return "co";
    case "deutschland":
      return "de";
    case "united Arab Emirates":
      return "ae";
    case "españa":
      return "es";
    case "france":
      return "fr";
    case "india":
      return "in";
    case "italia":
      return "it";
    case "日本":
      return "jp";
    case "luxembourg":
      return "lu";
    case "malaysia":
      return "my";
    case "méxico":
      return "mx";
    case "nederland":
      return "nl";
    case "norge":
      return "no";
    case "pilipinas":
      return "ph";
    case "schweiz":
      return "ch";
    case "singapore":
      return "sg";
    case "sverige":
      return "se";
    case "thailand":
      return "th";
    case "united kingdom":
      return "gb";
    case "united States":
      return "us";
    default:
      return false;
  }
};

export const getCountryRegion = country => {
  const countryLowerCase = country.toLowerCase();
  switch (countryLowerCase) {
    case "be":
    case "de":
    case "es":
    case "fr":
    case "it":
    case "lu":
    case "nl":
    case "no":
    case "ch":
    case "se":
    case "gb":
      return "europe";
    case "ar":
    case "br":
    case "ca":
    case "co":
    case "mx":
    case "us":
      return "america";
    default:
      return "asia";
  }
};

export const transformStringToPrice = str => {
  return str !== null && str !== undefined && str !== "" ? parseFloat(str.substring(2).replace(/\./g, "").replace(/\,/g, ".")) : 0;
};

export function createPathDealer(path, familyCode, bikeCode, vid, url, dealerCode) {
  let newPath = "";
  if (dealerCode?.length > 0) newPath = `${path}family_s=${familyCode}&model_s=${bikeCode}&vid=${vid}&url=${url}&pdealer=${dealerCode}`;
  else {
    newPath = `${path}family_s=${familyCode}&model_s=${bikeCode}&vid=${vid}&url=${url}`;
  }
  return newPath;
}

export function contains(array, find) {
  if (array !== undefined && array !== null && array.length > 0 && find !== "") {
    for (const item of array) {
      if (item.gruppo === find) {
        return true;
      }
    }
  }
  return false;
}

export function formatPrice(price, currency, carrello) {
  /* let priceWithCurrency = new Intl.NumberFormat(
    getCountryFromUrl().concat("-", getLanguage()),
    { style: "currency", currency: currency.symbol }
  ).format(price); */
  const str = parseFloat(price).toFixed(2).replace(/\./g, ",");
  let result = 0;
  if (price <= 0) {
    result = 0;
  } else if (str.length <= 6) {
    result = str;
  } else if (str.length === 7) {
    result = str.substring(0, 1) + "." + str.substring(1, str.length);
  } else if (str.length === 8) {
    result = str.substring(0, 2) + "." + str.substring(2, str.length);
  } else if (carrello) {
    result = str.substring(0, 3) + str.substring(3, str.length);
  } else {
    result = str.substring(0, 3) + "." + str.substring(3, str.length);
  }

  let priceWithCurrency;
  if (currency?.isStart) {
    priceWithCurrency = `${currency.symbol}${result}`;
  } else {
    priceWithCurrency = `${result}${currency?.symbol}`;
  }

  return priceWithCurrency;
}

export function BCP47LanguageCodeFromISO681LanguageCode(ISO681LanguageCode, ISO3166Country = "") {
  if ((ISO681LanguageCode === "nl" || ISO681LanguageCode === "fr") && ISO3166Country === "be") {
    return "fr-BE";
  } else if (ISO681LanguageCode === "de" && ISO3166Country === "de") {
    return "de-DE";
  } else if (ISO681LanguageCode === "es" && ISO3166Country === "es") {
    return "es-ES";
  } else if (ISO681LanguageCode === "fr" && ISO3166Country === "fr") {
    return "fr-FR";
  } else if (ISO681LanguageCode === "it" && ISO3166Country === "it") {
    return "it-IT";
  } else if (ISO681LanguageCode === "de" && ISO3166Country === "lu") {
    return "de-LU";
  } else if (ISO681LanguageCode === "fr" && ISO3166Country === "lu") {
    return "fr-LU";
  } else if (ISO681LanguageCode === "nl" && ISO3166Country === "nl") {
    return "nl-NL";
  } else if (ISO681LanguageCode === "nb" && ISO3166Country === "no") {
    return "nb-NO";
  } else if ((ISO681LanguageCode === "de" || ISO681LanguageCode === "fr" || ISO681LanguageCode === "it") && ISO3166Country === "ch") {
    return "de-CH";
  } else if (ISO681LanguageCode === "sv" && ISO3166Country === "se") {
    return "sv-SE";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "gb") {
    return "en-GB";
  } else if (ISO681LanguageCode === "es" && ISO3166Country === "ar") {
    return "es-AR";
  } else if (ISO681LanguageCode === "pt" && ISO3166Country === "br") {
    return "pt-BR";
  } else if ((ISO681LanguageCode === "en" || ISO681LanguageCode === "fr") && ISO3166Country === "ca") {
    return "en-CA";
  } else if (ISO681LanguageCode === "es" && ISO3166Country === "co") {
    return "es-CO";
  } else if (ISO681LanguageCode === "es" && ISO3166Country === "mx") {
    return "es-MX";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "au") {
    return "en-AU";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "in") {
    return "en-IN";
  } else if (ISO681LanguageCode === "ms" && ISO3166Country === "my") {
    return "ms-MY";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "ph") {
    return "en-PH";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "sg") {
    return "en-SG";
  } else if (ISO681LanguageCode === "en" && ISO3166Country === "th") {
    return "en-TH";
  } else if (ISO681LanguageCode === "er" && ISO3166Country === "ar") {
    return "er-AR";
  } else if (ISO681LanguageCode === "ja" && ISO3166Country === "jp") {
    return "ja-JP";
  }
  return "en-US";
}

export function formatPriceMTO({ price = 0, currency = "USD", country = "us", language = "en", minimumFractionDigits = 2 }) {
  const locale = BCP47LanguageCodeFromISO681LanguageCode(language, country);
  const options = {
    style: "currency",
    currency,
    currencyDisplay: [INDIAN_CURRENCY, MEXICAN_CURRENCY, PHILIPPINE_CURRENCY].includes(currency) ? "code" : "symbol",
    minimumFractionDigits: [
      CANADIAN_CURRENCY,
      MEXICAN_CURRENCY,
      SINGAPORE_CURRENCY,
      UNITED_ARAB_EMIRATES_CURRENCY,
      JAPANESE_CURRENCY,
    ].includes(currency)
      ? 0
      : minimumFractionDigits,
  };
  let formatted;
  if (price && currency) {
    formatted = Intl.NumberFormat(locale, options)
      .format(price)
      .toString()
      .replace(/^([\d,.]+)/, "$1 ")
      .replace(/([\d,.]+)$/, " $1")
      .replace(/\s+/, " ");
  } else if (price === 0 && currency) {
    formatted = Intl.NumberFormat(locale, options)
      .format(0)
      .toString()
      .replace(/^([\d,.]+)/, "$1 ")
      .replace(/([\d,.]+)$/, " $1")
      .replace(/\s+/, " ");
  }

  if (currency === SWISS_CURRENCY) {
    formatted = formatted.replace(SWISS_CURRENCY, CHF_SYMBOL);
  } else if (currency === NORWEGIAN_CURRENCY) {
    formatted = formatted.replace(KRONE_SYMBOL, "");
    formatted = `${KRONE_SYMBOL} `.concat(formatted);
  } else if (currency === CANADIAN_CURRENCY) {
    formatted = formatted.replace(DOLLAR_SYMBOL_UNICODE, CAD_SYMBOL);
  } else if (currency === PHILIPPINE_CURRENCY) {
    formatted = formatted.replace(PHILIPPINE_CURRENCY, PHP_SYMBOL);
  } else if (currency === SINGAPORE_CURRENCY) {
    formatted = formatted.replace(DOLLAR_SYMBOL_UNICODE, SGD_SYMBOL).replace(",", ".");
  } else if (currency === UNITED_ARAB_EMIRATES_CURRENCY) {
    formatted = formatted.replace(AED_SYMBOL, "").replace(",", " ").concat(` ${AED_SYMBOL}`);
  }
  if (country === "it") formatted = EUR_SYMBOL_UNICODE.concat(" ", formatted.slice(0, -1));
  return formatted;
}

export function arrayToMapAddElement(array, key, elementToAdd) {
  if (array) {
    const map = {};

    for (let i = 0; i < array.length; i++) {
      array[i].element = elementToAdd;
      if (key) map[array[i][key]] = array[i];
      else map[i] = array[i];
    }
    return map;
  }
  return null;
}

export function makeKeyDiv(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const sessionStorageKey = {
  feRedirectKey: "dhm_wbc20_fe_redirect",
  isAuthorizedKey: "is_auth_token",
  oauth2pkceAuthKey: "auth",
};

export function string2Bool(string) {
  return string !== null && string !== undefined && string === "true";
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function trimString(value) {
  return value.replace(/\s+/g, "");
}

export function removeContext() {
  localStorage.removeItem("initsessioncontent");
}


export function removeColorItem(list) {
  return list.filter(item => item.position !== 1);
}


export function getImageCountryFromText(text) {
  const images = {
    IT: italy,
    BE: belgium,
    AR: argentina,
    AU: australia,
    BR: brazil,
    CA: canada,
    CO: colombia,
    DE: germany,
    ES: spain,
    FR: france,
    IN: india,
    JP: japan,
    LU: luxembourg,
    MY: malaysia,
    MX: mexico,
    NL: netherlands,
    NO: norway,
    PH: philippines,
    CH: switzerland,
    SG: singapore,
    SE: sweden,
    TH: thailand,
    GB: uk,
    AE: emirates,
    US: usa,
    WW: international,
  };
  return images[text];
}

export function mapFamilyCompleteToListHeaderFamilyModel(data) {
  const newData = {
    familyModel: {},
  };
  data.forEach(family => {
    newData.familyModel[family.id] = {};
    newData.familyModel[family.id].name = family.name;
    newData.familyModel[family.id].list = [];
    family.models.forEach(model => {
      let flagModelRestricted = false;
      let flagModelNotRestricted = false;
      let newModel = {};
      model.preconfigurations.forEach(preconf => {
        if (preconf.restricted && !flagModelRestricted) {
          newModel = {
            key: preconf.id,
            modelName: model.id,
            value: model.name + " - 35KW",
            depo: 1,
          };
          newData.familyModel[family.id].list.push(newModel);
          flagModelRestricted = true;
        }
        if (!preconf.restricted && !flagModelNotRestricted) {
          newModel = {
            key: preconf.id,
            modelName: model.id,
            value: model.name,
            depo: 0,
          };
          newData.familyModel[family.id].list.push(newModel);
          flagModelNotRestricted = true;
        }
      });
      orderHeaderPotenziate(newData.familyModel[family.id].list);
      orderMenuHeader(newData.familyModel[family.id].list);
    });
  });

  return newData;
}

function orderMenuHeader(data) {
  if (data.length > 0) {
    data.sort((a, b) => (a.depo > b.depo ? 1 : -1));
  }
}

function orderHeaderPotenziate(data) {
  if (data.length > 0) {
    data.sort((a, b) => (a.value < b.value ? 1 : -1));
  }
}

function getObjectStore(store_name, mode, db) {
  const tx = db.transaction(store_name, mode);
  return tx.objectStore(store_name);
}

// transforms a string into a number
export function stringToNumber(str) {
  return parseFloat(str.replace(/\./g, "").replace(/\,/g, "."));
}

export function sortColorsByPrice(modelByColor) {
  const newModelByColor = {};
  for (const family in modelByColor) {
    newModelByColor[family] = {};
    for (const restrictedType in modelByColor[family]) {
      newModelByColor[family][restrictedType] = {};
      for (const subModel in modelByColor[family][restrictedType]) {
        newModelByColor[family][restrictedType][subModel] = [];
        const list = modelByColor[family][restrictedType][subModel];
        list.sort((a, b) => {
          if (a.colors[0].price_color < b.colors[0].price_color) return -1;
          else if (a.colors[0].price_color > b.colors[0].price_color) return 1;
          return 0;
        });
        newModelByColor[family][restrictedType][subModel] = list;
      }
    }
  }
  return newModelByColor;
}

export function getCorrectPrice(price) {
  return price > 0 ? price : "";
}

export function checkLastModelYear(modelYear) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  for (let i = 0; i < currentYear; i++) {
    if (parseInt(modelYear, 10) >= currentYear - i) return parseInt(modelYear, 10) >= currentYear - i;
  }
}

export function checkMobile() {
  return window.innerWidth < WIDTH_LARGE_MIN;
}

export function useOnHoverOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mouseover", listener);
    return () => {
      document.removeEventListener("mouseout", listener);
    };
  }, [ref, handler]);
}

export function displaybutton(rightSideRef, changeState) {
  if (rightSideRef?.current) {
    if (window.innerHeight >= rightSideRef.current.scrollHeight) {
      changeState(false);
    } else {
      changeState(true);
    }
  }
}

export function getStickyStyle(navbarStickyContent, style, webViewMode) {
  let stickyStyle = "";
  
  return stickyStyle;
}

export function isWebViewMode(webViewMode) {
  return webViewMode === true;
}

/**
 * This function compare the name of color contained in cart widh the default color name
 * @param {*} carrelloContent
 * @returns
 */
export function compareColorName(carrelloContent) {
  let result = false;
  if (carrelloContent?.listElementCarrello?.length > 0 && carrelloContent?.defaultElementList?.Colore?.name) {
    const cartColorName = arrayToMap(carrelloContent?.listElementCarrello, "gruppo").Colore?.name;
    const defaultColorName = carrelloContent?.defaultElementList?.Colore?.name;
    result = cartColorName === defaultColorName;
  }
  return result;
}

export function isBaseConfig(data, filteredBasePreconfiguration) {
  if (data?.model_code && filteredBasePreconfiguration && filteredBasePreconfiguration.length > 0) {
    const similarPreConfig = filteredBasePreconfiguration.filter(config => {
      return data.model_code === config.model_code;
    });
    if (similarPreConfig.length > 0) return similarPreConfig[0];
  }
  return false;
}

export function headerLabelStyler(label) {
  if (label.indexOf(".") > -1) {
    const splitLabel = label.split(".");
    return splitLabel[1].charAt(0).toUpperCase() + splitLabel[1].slice(1).toLowerCase();
  }
  return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
}

export function getCurrentDateYMD() {
  const currentDate = new Date();
  const dateYMD = [currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()].join("/");
  return dateYMD;
}

// TODO WORK IN PROGRESS
export function searchOption(fetchInit, fetchAccessory, attributeKey, domainValueKey) {
  for (const gruppo of fetchInit) {
    if (gruppo.length > 1) {
      for (const listaAccessori of gruppo) {
        for (const accessorio of listaAccessori) {
          if (!accessorio?.isGroupName) {
            if (accessorio.attributeKey === attributeKey && accessorio.domainValueKey === domainValueKey) {
              return accessorio;
            }
          }
        }
      }
    } else {
      for (const opzioni of gruppo) {
        for (const opzione of opzioni) {
          if (opzione.attributeKey === attributeKey && opzione.domainValueKey === domainValueKey) {
            return opzione;
          }
        }
      }
    }
  }
  for (const categoriaAccessori of fetchAccessory) {
    for (const accessorio of categoriaAccessori) {
      if (!accessorio?.isGroupName) {
        if (accessorio.attributeKey === attributeKey && accessorio.domainValueKey === domainValueKey) {
          return accessorio;
        }
      }
    }
  }
}

export function replacer(str, regex, character) {
  return str.replaceAll(regex, character);
}

export function isScrambler(familyName) {
  return familyName === scramblerFamilyLabel;
}

export const ducatiBrandChecker = brandID => {
  const id = brandID?.toLocaleLowerCase();
  return id === "ducati" || id === "scrambler";
};

export function isNullPriceCase(isMto, language, currentStep, step) {
  if (isMto && currentStep !== step && isCountryWithNoPrice(language.country.toLowerCase())) {
    return true;
  } else if (!isMto) {
    const colore = step.toLowerCase() === language?.steps_information["steps_information.name.maincolor"].toLowerCase();
    if (
      colore &&
      (currentStep.toLowerCase() === step.toLowerCase() ||
        currentStep.toLowerCase() === language?.steps_information["steps_information.name.color"].toLowerCase())
    ) {
      return isCountryWithNoPrice(language.country.toLowerCase());
    } else if (
      !colore &&
      (step.toLowerCase() === currentStep.toLowerCase() ||
        currentStep.toLowerCase() === language?.recap_print_information["recap_print.labels.aftermarket_accessories"]) &&
      isCountryWithNoPrice(language.country.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

function isCountryWithNoPrice(languageCountry) {
  switch (languageCountry) {
    case "ww":
      return true;
    case "co":
      return true;
    case "ar":
      return true;
    case "th":
      return true;
    default:
      return false;
  }
}

