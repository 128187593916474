import { useCallback } from "react";
import { headerLabelStyler } from "../../../utils/Utils";
import { useDucatiServiceMTO } from "../../../services/useDucatiServiceMTO";
import { startedFromType, useMTOContext } from "../../../context/MTOContext";
import { useLanguageContent } from "../../../context/LanguageContext";
import { useApparelsContent } from "../../../context/ApparelsContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APPARELS_GROUP, buildApparelsContent, buildHighlightedOutfits } from "../../../utils/Apparels";
import { conflictTypes, isThereConflict, useConflictData } from "../../modal/conflict-modal-mto/ConflictContainer";
import { useError } from "../../../context/ErrorContext";
import { useApparelsMTO } from "../../../hooks/useApparelsMTO";
import { useBikeEnrichment, useFamilyMTOComplete } from "../../../context/FamilyMTOContext";
import { AFTERMARKET_GROUP, useAftermarketMTO } from "../../../hooks/useAftermarketMTO";
import defaultImg from "../../../assets/images/DefaultImage.png";
import { messageType, useServiceModal } from "../../modal/service-modal/ServiceModal";
import { useSaveModal } from "../../modal/save-modal/SaveMyDucatiModal";
import { useSaveRecapLogin } from "../../../context/SaveRecapLoginContext";
import { useNavbarConfigurator } from "../../../context/NavbarConfiguratorContext";
import { builPageviewEvent } from "../../../context/data-layer/builder/PageviewBuilder";
import { pageTags, pageType, useDataLayerDispatch } from "../../../context/data-layer/DataLayerContext";

const CID_MTO_PREFIX = "MTO-";

export function useConfiguratorMTO() {
  const { getSuperModelInfo, findSpecificVersion, getFamilyInfo } = useFamilyMTOComplete();
  const { initializedContext, state, ...setters } = useMTOContext();
  const { language } = useLanguageContent();
  const { getTabI8N } = useNavbarConfigurator();
  const { setApparelsContent } = useApparelsContent();
  const [searchParams, setSearchParams] = useSearchParams();
  const { family, supermodel, version } = useParams();
  const { onError, echoError } = useError();
  const navigate = useNavigate();
  const { syncCartApparels } = useApparelsMTO();
  const {
    fetchEnrichmentMTO,
    fetchApparelsMTO,
    fetchInitMTO,
    fetchRetrieveConfigMTO,
    fetchAccessoriesMTO,
    fetchAccessoriesEnrichmentMTO,
    fetchSaveOnMyDucatiMTO,
  } = useDucatiServiceMTO();
  const { setAccessoryContent } = useAftermarketMTO();
  const { updateBikeEnrichment } = useBikeEnrichment();
  const { showConflictsModal } = useConflictData();
  const { showSaveModal } = useSaveModal();
  const { showServiceModal } = useServiceModal();
  const { saveConfig, setSaveConfig } = useSaveRecapLogin();
  const { dispatch } = useDataLayerDispatch();
  const { checkConfigURL } = useFamilyMTOComplete();

  const initBikeEnrichment = async (productCode, color) => {
    const bikeEnrichment = await fetchEnrichmentMTO(language.country.toLowerCase(), language.language.toLowerCase(), productCode, color)
      .then(response => {
        return updateBikeEnrichment(response);
      })
      .catch(error => {
        onError({
          httpStatusCode: error.message,
          consoleError: error,
          caller: "initBikeEnrichment - fetchEnrichmentMTO",
        });
      });
    return bikeEnrichment;
  };

  const getItemName = (bikeEnrichment, cha = "", value = "") => {
    const valuerKey = `${cha}_${value}`;
    return (
      bikeEnrichment[cha]?.valueMap[valuerKey]?.value ||
      bikeEnrichment[cha]?.valueMap[value]?.value ||
      bikeEnrichment[cha]?.cha ||
      undefined
    );
  };

  const getItemUrl = (bikeEnrichment, cha = "", value = "") => {
    const valuerKey = `${cha}_${value}`;
    return (
      bikeEnrichment[cha]?.valueMap[valuerKey]?.url || bikeEnrichment[cha]?.valueMap[value]?.url || bikeEnrichment[cha]?.url || undefined
    );
  };

  const updateMTOContext = useCallback(
    ({ backendResponse, familyCode, productCode, isMake = false, isTake = false, resAccessories, resApparels, bikeEnrichment }) => {
      /* INIT MTO */
      const MTOContext = [];
      const navList = [];
      setters.setCID(backendResponse.cid);
      setters.setVID(backendResponse.vid);
      setters.setConfigId(backendResponse.bikeConfigurationId);
      setters.setFamilyName(family);
      setters.setFamilyCode(familyCode);
      setters.setProductLine(backendResponse.modelData.productLine);
      setters.setModelName(supermodel);
      setters.setModelId(backendResponse?.modelData?.model || "");
      setters.setModelYear(backendResponse?.modelData?.modelYear || "");
      setters.setProductCode(productCode);
      setters.setSuperModelCode(backendResponse?.modelData?.superModel || "");
      const dealerURL = backendResponse?.urlB2C || "";
      const dealerURLVid = dealerURL?.length > 0 ? dealerURL.replace("?cid=", "?vid=") : "";
      setters.setUrlB2B(dealerURLVid);
      const notes = backendResponse?.quotation?.description !== "Quotation #" ? backendResponse?.quotation?.description : "";
      setters.setNotes(notes);
      if (isThereConflict(backendResponse.bikeConfiguration)) {
        showConflictsModal(backendResponse.bikeConfiguration);
      }
      // TODO MTO enable only when backend returns complete true
      // setters.setComplete(backendResponse.bikeConfiguration.complete);
      const filteredGroups = backendResponse.bikeConfiguration.groups.filter(item => !item.groupType.includes(conflictTypes.conflict));
      if (filteredGroups[0]?.attributes?.length > 0) {
        const first = filteredGroups[0].attributes[0];
        const firstVersion = first.domainValues.find(value => value.name === first.value);
        if (firstVersion) {
          setters.setBikeName(firstVersion.langDepName);
          const currencyIso = backendResponse?.currencyIsoCode || firstVersion?.price?.currencyIso;
          setters.setCurrencyIso(currencyIso);
        }
      }
      filteredGroups.forEach((group, index) => {
        const MTOContextGroup = [];
        if (index > 0 && index < 8) {
          if (!group.name.includes("PILOT SEAT") && !group.name.includes("BUNDLE") && !group.name.includes("BIT")) {
            if (!isTake) {
              const tabName = getTabI8N(group.name);
              navList.push({
                step: tabName?.length > 0 ? tabName : headerLabelStyler(group.name),
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach(attribute => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              attribute.domainValues.forEach(domainValue => {
                const langDepName = getItemName(bikeEnrichment, attribute.name, domainValue.key) || domainValue.langDepName;
                const MTOContextElement = {};
                MTOContextElement.domainValueKey = domainValue.key;
                MTOContextElement.attributeKey = attribute.key;
                MTOContextElement.groupId = group.id;
                const image = getItemUrl(bikeEnrichment, attribute.name, domainValue.key) || defaultImg;
                MTOContextElement.image = image;
                MTOContextElement.langDepName = langDepName;
                MTOContextElement.price = {
                  value: domainValue.price.value,
                  formattedValue: domainValue.price.formattedValue,
                  currency: domainValue.price.currencyIso,
                };
                MTOContextElement.selected = domainValue.selected;
                MTOContextElement.attributeName = headerLabelStyler(group.name);
                MTOContextAttribute.valueList.push(MTOContextElement);
                setters.synchronizeCart(domainValue.selected, {
                  groupId: group.id,
                  attributeKey: attribute.key,
                  domainValueKey: domainValue.key,
                  name: langDepName,
                  attributeName: headerLabelStyler(group.name),
                  price: {
                    value: domainValue.price.value,
                    formattedValue: domainValue.price.formattedValue,
                    currency: domainValue.price.currencyIso,
                  },
                  image,
                  description: domainValue.description,
                  selected: domainValue.selected,
                });
              });
              MTOContextGroup.push(MTOContextAttribute);
            });
            MTOContext.push(MTOContextGroup);
          } else if (group.name.includes("PILOT SEAT")) {
            // handle pilot seat group
            if (!isTake) {
              const tabName = getTabI8N(group.name);
              navList.push({
                step: tabName?.length > 0 ? tabName : headerLabelStyler(group.name),
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach(attribute => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              attribute.domainValues.forEach(domainValue => {
                const langDepName = getItemName(bikeEnrichment, attribute.name, domainValue.key) || domainValue.langDepName;
                const images = getItemUrl(bikeEnrichment, attribute.name, domainValue.key) || defaultImg;
                const chaName = getItemName(bikeEnrichment, attribute.name);
                const MTOContextElement = {};
                MTOContextElement.chaName = chaName;
                MTOContextElement.chaKey = attribute.name;
                MTOContextElement.domainValueKey = domainValue.key;
                MTOContextElement.attributeKey = attribute.key;
                MTOContextElement.groupId = group.id;
                MTOContextElement.image = images;
                MTOContextElement.langDepName = langDepName;
                MTOContextElement.price = {
                  value: domainValue.price.value,
                  formattedValue: domainValue.price.formattedValue,
                  currency: domainValue.price.currencyIso,
                };
                MTOContextElement.selected = domainValue.selected;
                MTOContextElement.attributeName = headerLabelStyler(group.name);
                MTOContextAttribute.valueList.push(MTOContextElement);
                setters.synchronizeCart(domainValue.selected, {
                  chaKey: attribute.name,
                  chaName,
                  groupId: group.id,
                  attributeKey: attribute.key,
                  domainValueKey: domainValue.key,
                  name: langDepName,
                  attributeName: headerLabelStyler(group.name),
                  price: {
                    value: domainValue.price.value,
                    formattedValue: domainValue.price.formattedValue,
                    currency: domainValue.price.currencyIso,
                  },
                  image: images,
                  description: domainValue.description,
                  selected: domainValue.selected,
                });
              });
              const MTOContextElement = {};
              MTOContextElement.langDepName = getItemName(bikeEnrichment, attribute.name) || attribute.langDepName;
              MTOContextElement.isGroupName = true;
              const sortedList = MTOContextAttribute.valueList.sort((a, b) => {
                if (a.domainValueKey < b.domainValueKey) {
                  return -1;
                }
                if (a.domainValueKey > b.domainValueKey) {
                  return 1;
                }
                return 0;
              });
              sortedList.push(MTOContextElement);
              MTOContextAttribute.valueList = sortedList;
              MTOContextGroup.push(MTOContextAttribute);
            });
            MTOContext.push(MTOContextGroup);
          } else if (group.name.includes("BUNDLE")) {
            // handle bundle group
            if (!isTake) {
              const tabName = getTabI8N(group.name);
              navList.push({
                step: tabName?.length > 0 ? tabName : headerLabelStyler(group.name),
                position: index,
                group: headerLabelStyler(group.name),
              });
            }
            group.attributes.forEach(attribute => {
              const MTOContextAttribute = {
                chaKey: attribute.name,
                valueList: [],
              };
              const MTOContextElement = {};
              const langDepName = getItemName(bikeEnrichment, attribute.name) || attribute.langDepName;
              const image = getItemUrl(bikeEnrichment, attribute.name) || defaultImg;
              MTOContextElement.selected = attribute.domainValues[0].selected;
              MTOContextElement.price = {
                value: attribute.domainValues[0].price.value,
                formattedValue: attribute.domainValues[0].price.formattedValue,
                currency: attribute.domainValues[0].price.currencyIso,
              };
              MTOContextElement.domainValueKey = attribute.name;
              MTOContextElement.url = attribute.url;
              MTOContextElement.attributeKey = attribute.key;
              MTOContextElement.groupId = group.id;
              MTOContextElement.image = image;
              MTOContextElement.langDepName = langDepName;
              MTOContextElement.description = attribute.description;
              MTOContextElement.attributeName = headerLabelStyler(group.name);
              MTOContextAttribute.valueList.push(MTOContextElement);
              MTOContextGroup.push(MTOContextAttribute);
              setters.synchronizeCart(attribute.domainValues[0].selected, {
                groupId: group.id,
                attributeKey: attribute.key,
                domainValueKey: attribute.name,
                name: langDepName,
                attributeName: headerLabelStyler(group.name),
                price: {
                  value: attribute.domainValues[0].price.value,
                  formattedValue: attribute.domainValues[0].price.formattedValue,
                  currency: attribute.domainValues[0].price.currencyIso,
                },
                image,
                description: attribute.description,
                selected: attribute.domainValues[0].selected,
              });
            });
            MTOContext.push(MTOContextGroup);
          }
        }
      });
      if (
        backendResponse?.bikeSection &&
        backendResponse?.accessorySection &&
        backendResponse?.apparelSection &&
        backendResponse?.overallSection
      ) {
        setters.updateTotalPrice({
          bikePriceFormatted: backendResponse.bikeSection.bikePriceFormatted,
          bikeBasePriceFormatted: backendResponse.bikeSection.bikeBasePriceFormatted || "",
          optionPriceFormatted: backendResponse.bikeSection.optionPriceFormatted,
          accessoryPriceFormatted: backendResponse.accessorySection.accessoryPriceFormatted,
          apparelPriceFormatted: backendResponse.apparelSection.apparelPriceFormatted,
          subTotalPriceFormatted: backendResponse.overallSection.subTotalPriceFormatted,
        });
        setters.updateQuotes(backendResponse);
      }

      if (!isMake) {
        if (resAccessories?.accessories?.length > 0) {
          navList.push({
            step: language.steps_information["steps_information.name.aftermarket"],
            position: navList.length + 1,
            group: AFTERMARKET_GROUP,
          });
        }
        if (resApparels?.length > 0) {
          navList.push({
            step: language.steps_information["cart_status.apparel.title.label"],
            position: navList.length + 1,
            group: APPARELS_GROUP,
          });
        }
      }
      setters.setHeaderMTO(navList);
      setters.setFetchInit(MTOContext);
      initializedContext.current = true;
    },
    [family, version]
  );

  const initAccessoryContent = (resAccessories, resAccEnrichment, accessoryItemTypeList) => {
    setAccessoryContent(resAccessories, resAccEnrichment, accessoryItemTypeList);
  };

  const initApparelContent = (resApparels, apparelSection) => {
    if (resApparels) {
      const outfits = {};
      let allApparels = {};
      let highlighted = [];
      if (resApparels?.length > 0) {
        allApparels = buildApparelsContent(resApparels);
        highlighted = buildHighlightedOutfits(resApparels);
        setApparelsContent({ outfits, allApparels, highlighted });
        if (apparelSection?.apparelItemTypeList?.length > 0) {
          syncCartApparels(apparelSection?.apparelItemTypeList, allApparels);
        }
      }
    }
  };

  const syncCartAmounts = resInitMTO => {
    if (resInitMTO?.bikeSection && resInitMTO?.accessorySection && resInitMTO?.apparelSection && resInitMTO?.overallSection) {
      setters.updateTotalPrice({
        bikePriceFormatted: resInitMTO.bikeSection.bikePriceFormatted,
        bikeBasePriceFormatted: resInitMTO.bikeSection.bikeBasePriceFormatted || "",
        optionPriceFormatted: resInitMTO.bikeSection.optionPriceFormatted,
        accessoryPriceFormatted: resInitMTO.accessorySection.accessoryPriceFormatted,
        apparelPriceFormatted: resInitMTO.apparelSection.apparelPriceFormatted,
        subTotalPriceFormatted: resInitMTO.overallSection.subTotalPriceFormatted,
      });
      setters.updateQuotes(resInitMTO);
    }
  };

  const initConfig = useCallback(() => {
    if (family && supermodel && version) {
      const smInfo = getSuperModelInfo(family, supermodel);
      if (smInfo) {
        const { familyCode, matnr } = smInfo;
        Promise.all([
          fetchInitMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            response => {
              return response;
            },
            error => {
              error.caller = "useConfiguratorMTO - initConfig - fetchInitMTO";
              throw error;
            }
          ),
          fetchAccessoriesMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            response => {
              return response;
            },
            error => {
              echoError({
                error,
                caller: "useConfiguratorMTO - initConfig - fetchAccessoriesMTO",
              });
              return undefined;
            }
          ),
          fetchAccessoriesEnrichmentMTO({
            country: language.country.toLowerCase(),
            language: language.language.toLowerCase(),
            productCode: matnr,
            version,
          }).then(
            response => {
              return response;
            },
            error => {
              echoError({
                error,
                caller: "useConfiguratorMTO - initConfig - fetchAccessoriesEnrichmentMTO",
              });
              return undefined;
            }
          ),
          fetchApparelsMTO(language.country.toLowerCase(), language.language.toLowerCase(), familyCode).then(
            response => {
              return response;
            },
            error => {
              echoError({
                error,
                caller: "useConfiguratorMTO - initConfig - fetchApparelsMTO",
              });
              return undefined;
            }
          ),
        ])
          .then(async values => {
            const resInitMTO = values[0];
            const resAccessories = values[1];
            const resAccEnrichment = values[2];
            const resApparels = values[3];
            let color = "";
            resInitMTO?.bikeConfiguration?.groups.forEach(item => {
              if (item?.name?.includes("COLOR")) {
                color = item?.attributes[0]?.formattedValue;
              }
            });
            const bikeEnrichment = await initBikeEnrichment(matnr, color);

            updateMTOContext({
              backendResponse: resInitMTO,
              familyCode,
              productCode: matnr,
              resAccessories,
              resApparels,
              bikeEnrichment,
            });
            setSearchParams({ cid: resInitMTO.cid });

            /* ACCESSORIES */
            initAccessoryContent(resAccessories, resAccEnrichment, resInitMTO?.accessorySection?.accessoryItemTypeList);

            /* APPARELS */
            initApparelContent(resApparels, resInitMTO?.apparelSection);

            syncCartAmounts(resInitMTO);
            pushPageviewConfigurator();
          })
          .catch(error => {
            onError({
              httpStatusCode: error.message,
              consoleError: error,
              caller: error.caller,
            });
          });
      }
    }
  }, [family, supermodel, version]);

  const getConfiguration = async cid => {
    const resInitMTO = await fetchRetrieveConfigMTO(language.country.toLowerCase(), language.language.toLowerCase(), cid)
      .then(response => {
        return response;
      })
      .catch(error => {
        onError({
          httpStatusCode: error.message,
          consoleError: error,
          caller: "getConfiguration - fetchRetrieveConfigMTO",
        });
      });
    return resInitMTO;
  };

  const getAccessories = async (productCode, version) => {
    const resApparels = await fetchAccessoriesMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      productCode,
      version,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        echoError({
          error,
          caller: "getAccessories - fetchAccessoriesMTO",
        });
        return undefined;
      });
    return resApparels;
  };

  const getAccEnrichmnet = async (productCode, version) => {
    const resAccEnrichment = await fetchAccessoriesEnrichmentMTO({
      country: language.country.toLowerCase(),
      language: language.language.toLowerCase(),
      productCode,
      version,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        echoError({
          error,
          caller: "getAccEnrichmnet - fetchAccessoriesEnrichmentMTO",
        });
        return undefined;
      });
    return resAccEnrichment;
  };

  const getApparels = async productLine => {
    const resApparels = await fetchApparelsMTO(language.country.toLowerCase(), language.language.toLowerCase(), productLine)
      .then(response => {
        return response;
      })
      .catch(error => {
        echoError({
          error,
          caller: "getApparels - fetchApparelsMTO",
        });
        return undefined;
      });
    return resApparels;
  };

  
  const isMakeScenario = backendResponse => {
    const enableTake = searchParams.get("fromConfiguration") || false;
    const startedFromValue = backendResponse?.marketPlaceInitialSelection || "";
    const enableMake =
      startedFromValue.toLowerCase() !== startedFromType.ORDER && startedFromValue.toLowerCase() !== startedFromType.STOCK && !enableTake;
    setters.setEnableRedirectOnSave(enableMake);
    return enableMake;
  };
  

  const retrieveConfig = useCallback(
    async cid => {
      const resInitMTO = await getConfiguration(cid);
      if (resInitMTO?.modelData && resInitMTO?.bikeConfiguration) {
        const familyInfo = getFamilyInfo(family);
        const { rootProduct } = resInitMTO.bikeConfiguration;

        let color = "";
        resInitMTO?.bikeConfiguration?.groups.forEach(item => {
          if (item?.name?.includes("COLOR")) {
            color = item?.attributes[0]?.formattedValue;
          }
        });
        const bikeEnrichment = await initBikeEnrichment(rootProduct, color);

        let isMake = false;
        let isTake = false;
        
        isMake = isMakeScenario(resInitMTO);
        isTake = !isMake;
        
        const { model } = resInitMTO.modelData;

        let resAccessories;
        let resAccEnrichment;
        let resApparels;
        if (!isMake) {
          resAccessories = await getAccessories(rootProduct, model);
          resAccEnrichment = await getAccEnrichmnet(rootProduct, model);
          resApparels = await getApparels(familyInfo?.familyCode);
        }

        // if (!isMake && !checkConfigURL(family, supermodel, version)) {
        //   const error = { message: NOT_FOUND_CODE };
        //   onError({
        //     httpStatusCode: NOT_FOUND_CODE,
        //     consoleError: error,
        //     caller: "Configurator - checkConfigURL",
        //   });
        // }


        updateMTOContext({
          backendResponse: resInitMTO,
          familyCode: familyInfo?.familyCode,
          productCode: rootProduct,
          isMake,
          isTake,
          resAccessories,
          resApparels,
          bikeEnrichment,
        });

        if (!isMake) {
          initAccessoryContent(resAccessories, resAccEnrichment, resInitMTO?.accessorySection?.accessoryItemTypeList);
          initApparelContent(resApparels, resInitMTO?.apparelSection);
        }

        syncCartAmounts(resInitMTO);
        pushPageviewConfigurator();
        if (saveConfig) {
          fetchSaveOnMyDucatiMTO(language.country.toLowerCase(), language.language.toLowerCase(), cid)
            .then(() => {
              setSaveConfig(false);
              showSaveModal();
            })
            .catch(error => {
              setSaveConfig(false);
              echoError({
                error,
                caller: "retrieveConfig - fetchSaveOnMyDucatiMTO",
              });
              showServiceModal(messageType.changingFail);
            });
        }
      }
    },
    [family]
  );

  const checkMTOConfig = useCallback(() => {
    const cid = searchParams.get("cid") || "";
    if (cid && cid.includes(CID_MTO_PREFIX)) {
      return true;
    }

    const vid = searchParams.get("vid") || "";
    if (vid && vid.includes(CID_MTO_PREFIX)) {
      return true;
    }
    
    return false;
  }, [family, supermodel, version]);

  const cloneConfig = useCallback(
    vid => {
      if (family && supermodel && version) {
        const smInfo = getSuperModelInfo(family, supermodel);
        if (smInfo) {
          const { familyCode, matnr } = smInfo;
          Promise.all([
            fetchInitMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
              vid,
            }).then(
              response => {
                return response;
              },
              error => {
                error.caller = "useConfiguratorMTO - cloneConfig - fetchInitMTO";
                throw error;
              }
            ),
            fetchAccessoriesMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
            }).then(
              response => {
                return response;
              },
              error => {
                echoError({
                  error,
                  caller: "useConfiguratorMTO - cloneConfig - fetchAccessoriesMTO",
                });
                return undefined;
              }
            ),
            fetchAccessoriesEnrichmentMTO({
              country: language.country.toLowerCase(),
              language: language.language.toLowerCase(),
              productCode: matnr,
              version,
            }).then(
              response => {
                return response;
              },
              error => {
                echoError({
                  error,
                  caller: "useConfiguratorMTO - cloneConfig - fetchAccessoriesEnrichmentMTO",
                });
                return undefined;
              }
            ),
            fetchApparelsMTO(language.country.toLowerCase(), language.language.toLowerCase(), familyCode).then(
              response => {
                return response;
              },
              error => {
                echoError({
                  error,
                  caller: "useConfiguratorMTO - cloneConfig - fetchApparelsMTO",
                });
                return undefined;
              }
            ),
          ])
            .then(async values => {
              const resInitMTO = values[0];
              const resAccessories = values[1];
              const resAccEnrichment = values[2];
              const resApparels = values[3];

              let color = "";
              resInitMTO?.bikeConfiguration?.groups.forEach(item => {
                if (item?.name?.includes("COLOR")) {
                  color = item?.attributes[0]?.formattedValue;
                }
              });
              const bikeEnrichment = await initBikeEnrichment(matnr, color);

              updateMTOContext({
                backendResponse: resInitMTO,
                familyCode,
                productCode: matnr,
                resAccessories,
                resApparels,
                bikeEnrichment,
              });

              /* ACCESSORIES */
              initAccessoryContent(resAccessories, resAccEnrichment, resInitMTO?.accessorySection?.accessoryItemTypeList);

              /* APPARELS */
              initApparelContent(resApparels, resInitMTO?.apparelSection);

              syncCartAmounts(resInitMTO);

              const pathname =
                "/bikes/" +
                language.country.toLowerCase() +
                "/" +
                language.language.toLowerCase() +
                "/" +
                family +
                "/" +
                supermodel +
                "/" +
                version +
                "/recap/editorial";

              navigate(pathname.concat("?vid=").concat(resInitMTO.vid));
            })
            .catch(error => {
              onError({
                httpStatusCode: error.message,
                consoleError: error,
                caller: "useConfiguratorMTO - cloneConfig",
              });
            });
        }
      }
    },
    [family, supermodel, version]
  );

  return {
    searchParams,
    initConfig,
    retrieveConfig,
    checkMTOConfig,
    cloneConfig,
  };

  function pushPageviewConfigurator(initData) {
    const pageviewEvent = builPageviewEvent({
      language: language?.language?.toLowerCase(),
      country: language?.country?.toLowerCase(),
      pageTags: pageTags.configurator,
      pageType: pageType.edit,
      pageTitle: `${language?.labels["configuration.families.title"]} - ${supermodel}`,
      familyName: family,
      modelName: supermodel,
      equipmentName: initData?.configuration?.preconfiguration?.preconfiguration || "",
    });
    dispatch(pageviewEvent);
  }
}

