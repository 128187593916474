export const DEFAULT_ENV_MAP_MTS = new Map([
  ["dsxrally", "env_offroad"],
  ["dsx", "env_offroad"],
  ["dsxdvry", "env_offroad"],
  ["msv4ri", "env_offroad"],
  ["msv4rti", "env_offroad"],
  ["msv4rtip", "env_offroad"],
  ["msv4rs", "env_racetrack"],
  ["msv4pp", "env_racetrack"],
  ["ss 950", "env_racetrack"],
  ["ss 950 s", "env_racetrack"],
  ["panv2", "env_racetrack"],
  ["panv2cor", "env_racetrack"],
  ["panv4", "env_racetrack"],
  ["panv4 r", "env_racetrack"],
  ["panv4 s", "env_racetrack"],
  ["panv4s7g", "env_racetrack"],
  ["panv47g", "env_racetrack"],
  ["pan896", "env_racetrack"],
  ["pan896s", "env_racetrack"],
  ["dvlv4", "env_urban"],
  ["m937", "env_urban"],
  ["m937 sp", "env_urban"],
  ["m937+", "env_urban"],
  ["sfv2", "env_urban"],
  ["sfv4", "env_urban"],
  ["sfv4 s", "env_urban"],
  ["sfv4 sp2", "env_urban"],
  ["sc800", "env_urban"],
  ["sc800ft", "env_urban"],
  ["sc800ns", "env_urban"],
  ["sc800dk", "env_urban"],
  ["xdvlv4", "env_luxuryvilla"],
  ["panv4sm1", "env_racetrack"],
  ["panv4sm2", "env_racetrack"],
  ["cr450mx", "env_studio_h"],
]);

