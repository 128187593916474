import { retrieveAdditionalInfoResources } from "../assets/resources/DucatiResources";
import { NOT_FOUND_CODE } from "../context/ErrorContext";

export function getDefaultCountryLanguages() {
  const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGES;
  const countryLanguageList = defaultLanguage.split(process.env.REACT_APP_DEFAULT_LANGUAGES_SEPARATOR);
  return countryLanguageList;
}

export function getLanguageByCountry(country) {
  const countryLanguageList = getDefaultCountryLanguages();
  for (const pair of countryLanguageList) {
    if (pair.startsWith(country)) {
      return pair.substring(pair.length - 2, pair.length);
    }
  }
}
export function splitPair(pair) {
  const pairSplitted = pair.split(":");
  return { country: pairSplitted[0], language: pairSplitted[1] };
}

export function checkCountry(country) {
  const countryLanguageList = getDefaultCountryLanguages();
  for (const pair of countryLanguageList) {
    if (pair.startsWith(country)) {
      return true;
    }
  }
  return false;
}

export function checkLanguage(language) {
  const countryLanguageList = getDefaultCountryLanguages();
  for (const pair of countryLanguageList) {
    if (pair.endsWith(language)) {
      return true;
    }
  }
  return false;
}

export function getCountryFromUrl() {
  const pattern = new RegExp(process.env.REACT_APP_PATTERN_URL, "i");
  let country = process.env.REACT_APP_DAFUALT_COUNTRY;

  if (pattern.test(window.location.pathname)) {
    const matches = pattern.exec(window.location.pathname);
    country = matches[2];
  }
  return country;
}

export function getLanguageFromUrl() {
  const pattern = new RegExp(process.env.REACT_APP_PATTERN_URL, "i");
  let language = null;

  if (pattern.test(window.location.pathname)) {
    const matches = pattern.exec(window.location.pathname);
    language = matches[3];
  }
  return language;
}

export function getBrowserLanguage() {
  return window.navigator.language.slice(0, 2);
}

export function getLanguage() {
  let language = process.env.REACT_APP_DAFUALT_LANGUAGE;
  const urlLang = getLanguageFromUrl();
  if (urlLang == null) {
    language = getLanguageByCountry(getCountryFromUrl());
  } else if (checkLanguage(urlLang)) {
    language = urlLang;
  }
  return language;
}

export const retrievei18n = async language => {
  const translation = await retrieveAdditionalInfoResources(language.toLowerCase()).then(
    data => {
      return data;
    },
    error => {
      error.message = NOT_FOUND_CODE;
      error.caller = "retrievei18n - retrieveAdditionalInfoResources";
      throw error;
    }
  );

  return translation;
};
